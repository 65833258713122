import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'evergreen-ui';
import PropTypes from 'prop-types';
import { LookupContext } from '../../../../../context/lookupContext';
import getNoImageFoundImageUrl from '../common/getNoImageFoundImageUrl';

const keys = {
  productName: 'Product Name',
  productSku: 'Product SKU',
  filterClass: 'Classification',
  productImage: 'Product Image',
};

const Product = ({ productSku }) => {
  const { productFeed } = useContext(LookupContext);
  const productFromFeed = productSku ? productFeed[productSku] : null;
  const [productDetails, setProductDetails] = useState({});

  useEffect(() => {
    if (productSku) {
      const details = {
        [keys.productName]: <div>{productFromFeed.name}</div>,
        [keys.productSku]: <div>{productFromFeed.sku}</div>,
        [keys.filterClass]: <div>{productFromFeed.classification}</div>,
        [keys.productImage]: (
          <div>
            <img alt="" height={180} width={200} src={productFromFeed?.imageUrl || getNoImageFoundImageUrl()} />
          </div>
        ),
      };

      setProductDetails(details);
    } else {
      setProductDetails({});
    }
  }, [productSku]);

  return (
    <Table.Body minHeight={400}>
      <Table.Head>
        <Table.TextCell flexBasis={560} flexShrink={0} flexGrow={0} maxWidth={250}>
          <h4>Product Details</h4>
        </Table.TextCell>
      </Table.Head>
      <Table.Body>
        {Object.keys(productDetails).map((key, index) => (
          <Table.Row key={`product-details-${index}`} minHeight={key === keys.productImage ? 190 : undefined}>
            <Table.TextCell flexBasis={560} flexShrink={0} flexGrow={0} maxWidth={250}>
              <h4>{key}</h4>
            </Table.TextCell>
            <Table.TextCell>{productDetails[key]}</Table.TextCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Body>
  );
};

Product.propTypes = {
  productSku: PropTypes.string.isRequired,
};

export default Product;
