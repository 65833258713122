/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Footer from '../../common/wizards/Footer';
import TextInput from '../../common/TextInput';
import IssueSelectionComponent from './IssueSelectionComponent';
import { getAdjustedPaymentTotal } from '../helpers/getAdjustedPaymentTotal';

const MAX_PERCENTAGES = {
  REFUND: 100,
  CREDITS: 125,
};

const Step3 = ({ order, suborder, state, updateAction, stepThreeState, updateStepThreeState, ...props }) => {
  const [issuingToCustomer, setIssuingToCustomer] = useState(stepThreeState.issuingToCustomer);
  const [issuingToRecipient, setIssuingToRecipient] = useState(stepThreeState.issuingToRecipient);
  const [issuingAccountCredits, setIssuingAccountCredits] = useState(stepThreeState.issuingAccountCredits);
  const [issuingRefund, setIssuingRefund] = useState(stepThreeState.issuingRefund);

  const [lineItemTotalWasSelected, setLineItemTotalWasSelected] = useState(stepThreeState.lineItemTotalWasSelected);
  const [grandTotalWasSelected, setGrandTotalWasSelected] = useState(stepThreeState.grandTotalWasSelected);
  const [customPercentageWasSelected, setCustomPercentageWasSelected] = useState(stepThreeState.customPercentageWasSelected);
  const [customDollarAmountWasSelected, setCustomDollarAmountWasSelected] = useState(stepThreeState.customDollarAmountWasSelected);

  const [percentageAmount, setPercentageAmount] = useState(stepThreeState.percentageAmount);

  const [creditAmountToCustomer, setCreditAmountToCustomer] = useState(state.creditAmountToCustomer);
  const [creditAmountToRecipient, setCreditAmountToRecipient] = useState(state.creditAmountToRecipient);
  const [creditCustomerEmail, setCreditCustomerEmail] = useState(state.creditCustomerEmail);
  const [creditRecipientEmail, setCreditRecipientEmail] = useState(state.creditRecipientEmail);
  const [refundAmount, setRefundAmount] = useState(state.refundAmount);

  /**
   * Refund only the amount the customer paid
   */
  const customerWasChargedAmount = getAdjustedPaymentTotal({ transactions: order.paymentSummary.transactions });

  const roundNumberToNearestHundredth = (num) => Math.round(num * 100) / 100;

  const maxCredits = roundNumberToNearestHundredth((MAX_PERCENTAGES.CREDITS / 100) * customerWasChargedAmount);

  const [amountForEntireStep, setAmountForEntireStep] = useState(stepThreeState.amountForEntireStep);

  useEffect(() => {
    updateStepThreeState({
      issuingToCustomer,
      issuingToRecipient,
      issuingAccountCredits,
      issuingRefund,
      lineItemTotalWasSelected,
      grandTotalWasSelected,
      customPercentageWasSelected,
      customDollarAmountWasSelected,
      amountForEntireStep,
      percentageAmount,
    });
  }, [
    issuingToCustomer,
    issuingToRecipient,
    issuingAccountCredits,
    issuingRefund,
    lineItemTotalWasSelected,
    grandTotalWasSelected,
    customPercentageWasSelected,
    customDollarAmountWasSelected,
    amountForEntireStep,
    percentageAmount,
  ]);

  useEffect(() => {
    updateAction({ creditCustomerEmail, creditRecipientEmail, refundAmount, creditAmountToCustomer, creditAmountToRecipient });
  }, [creditCustomerEmail, creditRecipientEmail, refundAmount, creditAmountToCustomer, creditAmountToRecipient]);

  const refundAndCreditLogic = () => {
    const newValues = {
      refundAmount: issuingRefund ? (grandTotalWasSelected ? null : amountForEntireStep) : 0,
      creditAmountToCustomer: issuingAccountCredits && issuingToCustomer ? amountForEntireStep : 0,
      creditAmountToRecipient: issuingAccountCredits && issuingToRecipient ? amountForEntireStep : 0,
    };
    setRefundAmount(newValues.refundAmount);
    setCreditAmountToCustomer(newValues.creditAmountToCustomer);
    setCreditAmountToRecipient(newValues.creditAmountToRecipient);
  };

  useEffect(() => {
    if (issuingToCustomer) {
      if (creditCustomerEmail === '') {
        if (state.creditCustomerEmail === '' && order.customer && order.customer.email) {
          setCreditCustomerEmail(order.customer.email);
        }
      }
      refundAndCreditLogic();
    }
  }, [issuingToCustomer]);

  useEffect(() => {
    if (issuingToRecipient) {
      if (creditRecipientEmail === '') {
        if (state.creditRecipientEmail === '' && suborder.recipient && suborder.recipient.email) {
          setCreditRecipientEmail(suborder.recipient.email);
        }
      }

      setIssuingAccountCredits(true);
      setIssuingRefund(false);

      refundAndCreditLogic();
    }
  }, [issuingToRecipient]);

  useEffect(() => {
    // This condition purpose is for Refund only, if switched to refund will reduced the Percentage and Dollar Amount by 25%
    if (issuingRefund) {
      setPercentageAmount(percentageAmount > MAX_PERCENTAGES.REFUND ? MAX_PERCENTAGES.REFUND : percentageAmount);
      setAmountForEntireStep(amountForEntireStep > customerWasChargedAmount ? customerWasChargedAmount : amountForEntireStep);
    }
  }, [issuingRefund]);

  useEffect(refundAndCreditLogic, [issuingAccountCredits, issuingRefund, amountForEntireStep]);

  const setFirstAndUnsetTheRest = (val, firstSetter, ...restOfSetters) => {
    firstSetter(val);
    for (const setter of restOfSetters) {
      setter(!val);
    }
  };

  const getCurrentEmail = () => (issuingToCustomer ? creditCustomerEmail : creditRecipientEmail);

  const getEmailForm = () => {
    const identifier = issuingToCustomer ? 'customer' : 'recipient';
    const value = getCurrentEmail();
    const setter = issuingToCustomer ? setCreditCustomerEmail : setCreditRecipientEmail;

    return (
      <div className="row">
        <div>
          Email of <span className="font-weight-bold">{identifier}</span>
        </div>
        <TextInput placeholder={`${identifier} email`} name="EmailInput" value={value} onChange={(event) => setter(event.target.value)} />
      </div>
    );
  };

  const lineItemCreationTrackingExists = suborder.lineItems.filter((lineItem) => lineItem.createdOnInitialOrderAt).length > 0;

  const lineItemsToParseThrough =
    state.lineItemsWithIssue.length === 0
      ? lineItemCreationTrackingExists
        ? suborder.lineItems.filter((lineItem) => lineItem.createdOnInitialOrderAt)
        : suborder.lineItems.filter((lineItem) => !lineItem.isDeleted)
      : state.lineItemsWithIssue;

  const summationLineItems = suborder.saleLineItems
    ? lineItemsToParseThrough.filter((li) => li.saleLineItemIndex !== undefined)
    : lineItemsToParseThrough;

  const getSummationOfArrayBasedOnMapping = (focusArray, mapping) => focusArray.map(mapping).reduce((a, b) => a + b, 0);

  const getItemTaxTotal = () => {
    if (!suborder.saleLineItems) {
      return getSummationOfArrayBasedOnMapping(summationLineItems, (lineItem) => lineItem.taxAmount);
    }

    let sum = 0;

    for (const summationLineItem of summationLineItems) {
      const { saleLineItemIndex } = summationLineItem;
      const saleLineItem = suborder.saleLineItems[saleLineItemIndex];
      const percentage = summationLineItem.price / saleLineItem.price;
      const trueTaxAmount = percentage * saleLineItem.taxAmount;

      sum += trueTaxAmount;
    }
    return sum;
  };

  let itemTotal = getSummationOfArrayBasedOnMapping(summationLineItems, (lineItem) => lineItem.price);
  let itemTaxTotal = getItemTaxTotal();
  if (order?.shopifyTransactions) {
    itemTotal = order.shopifyTransactions.netPayment.amount || 0;
    itemTaxTotal = order.shopifyTransactions.taxTotal.amount || 0;
  }

  useEffect(() => {
    if (customPercentageWasSelected) {
      const amountAfterPercentageApplied = customerWasChargedAmount * (percentageAmount / 100);
      const rounded = roundNumberToNearestHundredth(amountAfterPercentageApplied);
      setAmountForEntireStep(rounded);
    }
  }, [percentageAmount]);

  const ensureNumberBetween = (val, min, max) => parseInt(val) && Number(val) > min && Number(val) <= max;

  const getPercentageInput = () => (
    <div className="row pt-3">
      <div className="col-auto col-sm-3 pr-0">
        <TextInput
          type="number"
          validate={(val) => ensureNumberBetween(val, 0, issuingAccountCredits ? MAX_PERCENTAGES.CREDITS : MAX_PERCENTAGES.REFUND)}
          value={percentageAmount.toString()}
          name="customPercentageInput"
          onChange={(event) => {
            const { value } = event.target;
            let asNum = Number(value);
            if (issuingAccountCredits) {
              if (asNum > MAX_PERCENTAGES.CREDITS) {
                asNum = MAX_PERCENTAGES.CREDITS;
              }
            } else if (asNum > MAX_PERCENTAGES.REFUND) {
                asNum = MAX_PERCENTAGES.REFUND;
              }
            setPercentageAmount(asNum);
          }}
        />
      </div>
      <div className="col col-sm-1 mt-2 pl-1">%</div>
    </div>
  );

  const getDollarAmountInput = () => (
    <div className="row pt-3">
      <div className="col-auto col-xs-1 mt-2 pr-1">$</div>
      <div className="col-auto col-sm-3 pl-0">
        <TextInput
          type="number"
          validate={(val) => ensureNumberBetween(val, 0, issuingAccountCredits ? maxCredits : customerWasChargedAmount)}
          name="customDollarAmountInput"
          value={amountForEntireStep.toString()}
          onChange={(event) => {
            const { value } = event.target;
            let dollarAmountValue = value;
            const forConditions = parseFloat(value);

            if (issuingAccountCredits) {
              if (forConditions > maxCredits) {
                dollarAmountValue = maxCredits;
              }
            } else if (order?.shopifyTransactions && forConditions < Number(order.shopifyTransactions.netPayment.amount)) {
              // dollarAmountValue = dollarAmountValue;
              // Do nothing
            } else if (forConditions > customerWasChargedAmount) {
              dollarAmountValue = customerWasChargedAmount;
            }
            setAmountForEntireStep(dollarAmountValue);
          }}
        />
      </div>
    </div>
  );

  const getDisplayAmount = () => {
    if (issuingAccountCredits) {
      if (issuingToCustomer) return creditAmountToCustomer;
      if (issuingToRecipient) return creditAmountToRecipient;
    }
    if (grandTotalWasSelected) return customerWasChargedAmount;
    if (issuingRefund) return refundAmount;
    return 0;
  };

  const runExcessDataClearance = () => {
    if (issuingAccountCredits) {
      if (issuingToCustomer) {
        setCreditRecipientEmail('');
      }
      if (issuingToRecipient) {
        setCreditCustomerEmail('');
      }
    }
    if (issuingRefund) {
      setCreditRecipientEmail('');
      setCreditCustomerEmail('');
    }
  };

  const forwardAction = () => {
    runExcessDataClearance();

    props.nextStep();
  };

  return (
    <>
      <h2 className="text-center mb-2">Refund or Give Account Credits</h2>
      <p className="text-muted text-center mb-4">
        Choose whether to issue a refund to the customer or issue account credits to the customer or recipient.
      </p>
      <div className="mt-4">Who should we issue funds to?</div>
      <div className="row align-items-center">
        <div className="col">
          <IssueSelectionComponent
            issueText="Send to the customer"
            issueSubtext={`Refund${state.needsRedelivery ? '' : '/credit'} the person who paid for the order.`}
            isSelected={issuingToCustomer && !issuingToRecipient}
            setIsSelected={(val) => setFirstAndUnsetTheRest(val, setIssuingToCustomer, setIssuingToRecipient)}
          />
        </div>
        {!state.needsRedelivery && (
          <div className="col">
            <IssueSelectionComponent
              issueText="Send to the recipient"
              issueSubtext="Account credits only. This is less common"
              isSelected={issuingToRecipient}
              setIsSelected={(val) => setFirstAndUnsetTheRest(val, setIssuingToRecipient, setIssuingToCustomer)}
            />
          </div>
        )}
      </div>
      {(issuingToCustomer || issuingToRecipient) && (
        <>
          {getEmailForm()}
          <div className="row">
            <div className="mt-2">Should we issue account credits or a refund?</div>
            <div className="row">
              {!state.needsRedelivery && (
                <div className="col">
                  <IssueSelectionComponent
                    issueText="Account Credits"
                    isSelected={issuingAccountCredits}
                    setIsSelected={(val) => setFirstAndUnsetTheRest(val, setIssuingAccountCredits, setIssuingRefund)}
                  />
                </div>
              )}
              {!issuingToRecipient && (
                <div className="col">
                  <IssueSelectionComponent
                    issueText="Refund"
                    isSelected={issuingRefund}
                    setIsSelected={(val) => setFirstAndUnsetTheRest(val, setIssuingRefund, setIssuingAccountCredits)}
                  />
                </div>
              )}
            </div>
          </div>
          {(issuingAccountCredits || issuingRefund) && (
            <>
              <div className="mt-2">
                Amount <span className="font-weight-bold">{issuingAccountCredits ? 'of credits to issue' : 'to refund'}</span>
              </div>
              {summationLineItems && summationLineItems.length > 0 ? (
                <IssueSelectionComponent
                  issuePretext="Total of impacted line items:"
                  issueText={`$${itemTotal} + $${itemTaxTotal} tax`}
                  isSelected={lineItemTotalWasSelected}
                  setIsSelected={(val) => setFirstAndUnsetTheRest(
                      val,
                      setLineItemTotalWasSelected,
                      setGrandTotalWasSelected,
                      setCustomPercentageWasSelected,
                      setCustomDollarAmountWasSelected,
                    )}
                  onSelection={() => setAmountForEntireStep(itemTotal)}
                  additionalWrapperClass=" mb-2"
                />
              ) : (
                <IssueSelectionComponent
                  issuePretext="Total of impacted line items:"
                  issueText="None available because no purchased line items were selected."
                  isSelected={false}
                  setIsSelected={() => {}}
                  isDisabled
                  additionalWrapperClass=" mb-2"
                />
              )}
              <IssueSelectionComponent
                issuePretext="All Charged Payment:"
                issueText={`$${customerWasChargedAmount}`}
                isSelected={grandTotalWasSelected}
                setIsSelected={(val) => setFirstAndUnsetTheRest(
                    val,
                    setGrandTotalWasSelected,
                    setLineItemTotalWasSelected,
                    setCustomPercentageWasSelected,
                    setCustomDollarAmountWasSelected,
                  )}
                onSelection={() => setAmountForEntireStep(customerWasChargedAmount)}
                additionalWrapperClass=" mb-2"
              />
              <IssueSelectionComponent
                issuePretext="Custom"
                issueText="Percentage"
                isSelected={customPercentageWasSelected}
                setIsSelected={(val) => setFirstAndUnsetTheRest(
                    val,
                    setCustomPercentageWasSelected,
                    setLineItemTotalWasSelected,
                    setGrandTotalWasSelected,
                    setCustomDollarAmountWasSelected,
                  )}
                onSelection={() => setPercentageAmount(0)}
                postTextComponents={getPercentageInput()}
                additionalWrapperClass=" mb-2"
              />
              <IssueSelectionComponent
                issuePretext="Custom"
                issueText="Dollar Amount"
                isSelected={customDollarAmountWasSelected}
                setIsSelected={(val) => setFirstAndUnsetTheRest(
                    val,
                    setCustomDollarAmountWasSelected,
                    setLineItemTotalWasSelected,
                    setGrandTotalWasSelected,
                    setCustomPercentageWasSelected,
                  )}
                onSelection={() => setAmountForEntireStep(0.0)}
                postTextComponents={getDollarAmountInput()}
                additionalWrapperClass=" mb-2"
              />

              <div className="mt-4">
                <span className="font-weight-bold">
                  ${getDisplayAmount()}
                  {!grandTotalWasSelected && !(amountForEntireStep === customerWasChargedAmount) && ' + tax'}
                </span>{' '}
                will be <span className="font-weight-bold">{issuingAccountCredits ? 'credited' : 'refunded'}</span> to{' '}
                <span className={issuingAccountCredits ? 'font-weight-bold' : ''}>
                  {issuingAccountCredits ? getCurrentEmail() : 'the original payment methods.'}
                </span>
              </div>
            </>
          )}
        </>
      )}
      <Footer
        backActionText="Back"
        backAction={props.previousStep}
        forwardActionText="Continue"
        forwardAction={forwardAction}
        disableForwardOn={
          (!issuingToCustomer && !issuingToRecipient) ||
          (!issuingAccountCredits && !issuingRefund) ||
          (!creditCustomerEmail.includes('@') && !creditRecipientEmail.includes('@')) ||
          (!(refundAmount || (!refundAmount && grandTotalWasSelected)) && !creditAmountToCustomer && !creditAmountToRecipient)
        }
      />
    </>
  );
};

export default Step3;
