import React from 'react';
import PropTypes from 'prop-types';

import FulfillmentCenterAndDateForm from '../common/forms/FulfillmentCenterAndDateForm';

const PageHeader = ({ category, title, showDatePicker, backgroundColorClass, borderBottom, showFcPicker }) => (
  <div className={`${backgroundColorClass}`}>
    <div className="container-fluid">
      <div className="header-body" style={{ borderBottom }}>
        <div className="row justify-content-center">
          <div className="col">
            <h6 className="header-pretitle text-secondary">{category}</h6>
            <h1 className="header-title text-white">{title}</h1>
          </div>
          <div className="col-auto">
            <FulfillmentCenterAndDateForm showDatePicker={showDatePicker} showFcPicker={showFcPicker} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

PageHeader.propTypes = {
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  showDatePicker: PropTypes.bool,
  backgroundColorClass: PropTypes.string,
  borderBottom: PropTypes.string,
  showFcPicker: PropTypes.bool,
};
PageHeader.defaultProps = {
  showDatePicker: true,
  backgroundColorClass: 'header bg-dark',
  borderBottom: '1px solid #e3ebf6',
  showFcPicker: true,
};
export default PageHeader;
