/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-new */
/* eslint-disable no-promise-executor-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useEffect, useState, useContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import { Checkbox, Dialog, Switch, Spinner, Button } from 'evergreen-ui';
import { DateTime } from 'luxon';
import { getDeliveryWindows, getAreas, getDeliveryAreaOptions } from '../../services/AggregateService';
import { getSuborder, updateSuborderDelivery } from '../../services/OrderManagementService';
import { LookupContext } from '../../context/lookupContext';
import { AuthContext } from '../../context/authContext';
import TextInput from '../common/TextInput';
import { roleChecks } from '../../utilities/Role';
// eslint-disable-next-line import/no-cycle
import { deallocateStrategies } from './ViewOrder';
import DeliveryOptionSelectionComponent from './helpers/DeliveryOptionSelectionComponent';
import BestOptionDatePicker from './helpers/BestOptionDatePicker';
import { NETSUITE_INVENTORY_FEATURE_FLAG } from '../../config';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css';

const getCoordsForSuborder = async (suborder) => {
  const omsSuborderResponse = await getSuborder(suborder.suborderId);

  if (!omsSuborderResponse.success) {
    throw Error(`No OMS suborder found on lookup: ${suborder.suborderId}`);
  }

  const omsSuborder = omsSuborderResponse.suborder;

  return { coords: omsSuborder.recipient.coords, zipCode: omsSuborder.recipient.zipCode };
};

const getNonDeletedLineItems = (lineItemArray) => lineItemArray.filter((li) => !li.isDeleted);

const getAllowedTransportModes = (lineItems, productFeed) => {
  const arrayOfLineItemTransportModeArrays = [];
  const skus = lineItems.map((li) => li.sku);

  for (const sku of skus) {
    if (productFeed[sku]) {
      const product = productFeed[sku];
      const { allowedTransportModes } = product;
      arrayOfLineItemTransportModeArrays.push(allowedTransportModes);
    }
  }

  if (!arrayOfLineItemTransportModeArrays.length) return [];

  return arrayOfLineItemTransportModeArrays.reduce((a, b) => a.filter((c) => b.includes(c)));
};

export const parseFlexibleDate = (startDateString, endDateString, window, zone) => {
  const possibleFormats = [
    'yyyy-MM-dd HH:mm:ssZZ',
    'yyyy-MM-dd HH:mm:ss',
    'yyyy-MM-dd',
    "yyyy-MM-dd'T'HH:mm:ss",
    "yyyy-MM-dd'T'HH:mm:ssZZ",
    'yyyy-MM-dd HH:mm:ss.SSS ZZ',
    'yyyy-MM-dd HH:mm:ss.SSS',
  ];

  let startDateTime = null;
  let endDateTime = null;

  for (const format of possibleFormats) {
    startDateTime = zone
      ? DateTime.fromFormat(startDateString, format, { zone })
      : DateTime.fromFormat(startDateString, format);
    if (startDateTime.isValid) {
      break;
    }
  }

  for (const format of possibleFormats) {
    endDateTime = zone
      ? DateTime.fromFormat(endDateString, format, { zone })
      : DateTime.fromFormat(endDateString, format);
    if (endDateTime.isValid) {
      break;
    }
  }

  if (!startDateTime?.isValid) {
    startDateTime = zone
      ? DateTime.fromISO(startDateString, { zone })
      : DateTime.fromISO(startDateString);
  }
  if (!endDateTime?.isValid) {
    endDateTime = zone
      ? DateTime.fromISO(endDateString, { zone })
      : DateTime.fromISO(endDateString);
  }

  if (!startDateTime.isValid || !endDateTime.isValid) {
    throw new Error(`Invalid date format: start="${startDateString}", end="${endDateString}"`);
  }

  return {
    value: { window, startTime: window.starts_at, duration: window.duration },
    label: `${startDateTime.toFormat('hh:mm a')} - ${endDateTime.toFormat('hh:mm a')}`,
  };
};

const convertOptionToOmsFriendlyOption = (option, deliveryAreaLookup, deliveryAreas) => {
  const window = parseFlexibleDate(option.starts_at, option.ends_at, option, option.timezone);
  const omsFriendlyOption = {
    deliveryAreaName: deliveryAreaLookup[option.deliveryArea.id] ? deliveryAreaLookup[option.deliveryArea.id].description : '',
    windowDescription: window.label,
    startTime: option.window.starts_at,
    windowDuration: option.window.duration,
    orderFulfilledBy: option.window.order_prepped_by,
    areaId: option.deliveryArea.id,
    transport: option.deliveryArea.delivery_type,
    transportMode: option.deliveryArea.transportMode,
    fc: {
      ...option.fc,
      fcId: option.fc.id,
      zipCode: option.fc.zipcode,
    },
    transportVendorDeliveryName: deliveryAreas[option.deliveryArea.id].transportVendor,
    transportVendorDelivery: {
      name: deliveryAreas[option.deliveryArea.id].transportVendor,
    },
  };
  delete omsFriendlyOption.fc.id;
  delete omsFriendlyOption.fc.zipcode;

  return omsFriendlyOption;
};

const UpdateDelivery = ({
  suborders,
  fcId,
  deliveryAreas,
  showChangeDelivery,
  setShowChangeDelivery,
  onComplete,
  coords,
  zipCode,
  newOrder,
  setDeliveryFields,
  useDialog = true,
  redeliveryArgs,
  setDisable = () => {},
}) => {
  const [areasFromCoordsApi, setAreasFromCoordsApi] = useState(null);
  const { deliveryAreas: deliveryAreaLookup, fulfillmentCenters, productFeed } = useContext(LookupContext);
  const { userGroups } = useContext(AuthContext);
  const [fcOptions, setFcOptions] = useState(null);
  const [fc, setFc] = useState(null);
  const [deliveryArea, setDeliveryArea] = useState(null);
  const [areaOptions, setAreaOptions] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(redeliveryArgs ? redeliveryArgs.deliveryDate : null);
  const [windowOptions, setWindowOptions] = useState([]);
  const [deliveryWindow, setDeliveryWindow] = useState(null);

  const [totalToSave, setTotalToSave] = useState(0);
  const [saveFailures, setSaveFailures] = useState([]);
  const [totalSaved, totalSavedDispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'increment':
        return state + 1;
      case 'reset':
        return 0;
      default:
        return state;
    }
  }, 0);

  const [addNewDeliveryWindow, setAddNewDeliveryWindow] = useState(false);
  const [overrideDeliveryTime, setOverrideDeliveryTime] = useState(null);
  const [overrideDeliveryDuration, setOverrideDeliveryDuration] = useState('');

  const [deallocateStrategy, setDeallocateStrategy] = useState('delete');
  const [usingBestOptionsToggle, setBestOptionToggle] = useState(!newOrder);
  const [bestOptionDate, setBestOptionDate] = useState(redeliveryArgs ? redeliveryArgs.deliveryDate : null);
  const [mapOfSuborderIdsToBestOptionsArray, setMapOfSuborderIdsToBestOptionsArray] = useState({});
  const [mapOfSuborderIdsToErrorMessagesForBestOptions, setMapOfSuborderIdsToErrorMessagesForBestOptions] = useState({});
  const [selectedBestOption, setSelectedBestOption] = useState(null);
  const [saveSuccessIds, setSaveSuccessIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [bestOptionDeliveryFields, setBestOptionDeliveryFields] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [buttonText, setButtonText] = useState('Cancel');

  const isBulk = suborders && suborders.length > 1;
  const hasCoordsAndZip = (suborders?.length === 1 && coords && zipCode) || ((newOrder || redeliveryArgs) && coords && zipCode);

  const disableSave =
    isUpdating ||
    saveFailures.length ||
    (addNewDeliveryWindow
      ? !(deliveryArea && fc && overrideDeliveryTime && overrideDeliveryDuration)
      : usingBestOptionsToggle
      ? isLoading || !bestOptionDate || !selectedBestOption
      : !(deliveryWindow && deliveryArea && fc && deliveryDate));

  // Checks if suborders have the same current values for area, date, and window
  const subordersProps = useMemo(() => {
    if (newOrder) {
      return {
        deliveryArea: null,
        deliveryDate: null,
        window: null,
      };
    }
    if (redeliveryArgs) {
      return {
        deliveryArea: redeliveryArgs.deliveryArea,
        deliveryDate: redeliveryArgs.deliveryDate,
        window: redeliveryArgs.window,
      };
    }
    return {
      deliveryArea: new Set(suborders.map((s) => s.deliveryArea)).size === 1 ? suborders[0].deliveryArea : null,
      deliveryDate:
        new Set(suborders.filter((s) => s.startTime != null).map((s) => s.startTime.split('T')[0])).size === 1
          ? suborders[0].startTime.split('T')[0]
          : null,
      window: new Set(suborders.map((s) => s.window)).size === 1 ? suborders[0].window : null,
    };
  }, [suborders, newOrder]);

  // Form Submit
  const handleUpdateDelivery = async (e) => {
    if (e) {
      e.preventDefault();
    }

    setIsUpdating(true);
    setButtonText('Close');

    const fields = {};
    const options = {
      useCareBuffer: true,
    };

    if (!usingBestOptionsToggle) {
      // update FC
      const fcInfo = fulfillmentCenters[fc.value];
      if (Number(fc.value) !== fcId) {
        fields.fc = {
          fcId: Number(fc.value),
          timezone: fcInfo.timezone,
          company: fcInfo.company,
          address1: fcInfo.address1,
          address2: fcInfo.address2,
          city: fcInfo.city,
          state: fcInfo.state,
          zipCode: fcInfo.zipcode,
          phone: fcInfo.phone,
        };
      }
      // populate fields using regular conventions
      // update date and window, compute proper fields if using window override
      const { timezone } = fcInfo;

      if (addNewDeliveryWindow) {
        const startDate = DateTime.fromISO(deliveryDate, { zone: timezone });
        const startDateTime = startDate.set({
          hour: DateTime.fromJSDate(overrideDeliveryTime).hour,
          minute: DateTime.fromJSDate(overrideDeliveryTime).minute,
        });

        const fulfilledBy = startDateTime.minus({ minutes: deliveryAreas[deliveryArea.value].travelTime });

        fields.startTime = DateTime.fromISO(startDateTime).toSQL();
        fields.windowDuration = Number(overrideDeliveryDuration);
        fields.orderFulfilledBy = DateTime.fromISO(fulfilledBy).toSQL();
      } else if (deliveryDate !== subordersProps.deliveryDate || subordersProps.window !== deliveryWindow.value.duration) {
        fields.startTime = DateTime.fromISO(deliveryWindow.value.startTime).toSQL();
        fields.windowDuration = deliveryWindow.value.duration;
        fields.orderFulfilledBy = deliveryWindow.value.window.order_fulfilled_by;
      }

      // update area
      if (deliveryArea.value !== subordersProps.deliveryArea || redeliveryArgs) {
        fields.areaId = deliveryArea.value;
        fields.transport = deliveryAreas[deliveryArea.value].transport;
        fields.transportMode = deliveryAreas[deliveryArea.value].transportMode;
        fields.transportVendorDeliveryName = deliveryAreas[deliveryArea.value].transportVendor;
      }
    }

    options.deallocateStrategy = deallocateStrategy;

    if (newOrder || redeliveryArgs) {
      try {
        setDeliveryFields({
          areaId: fields.areaId,
          startTime: fields.startTime,
          orderFulfilledBy: fields.orderFulfilledBy,
          windowDuration: fields.windowDuration,
          transport: deliveryAreas[fields.areaId].transport,
          transportMode: deliveryAreas[fields.areaId].transportMode,
          transportVendorDeliveryName: deliveryAreas[fields.areaId].transportVendor,
          fc: { ...fields.fc },
          transportVendorDelivery: {
            name: deliveryAreas[fields.areaId].transportVendor,
          },
        });
        setShowChangeDelivery(false);
        setDisable(false);
      } catch (e) {
        console.error('Reaching error in setting fields', e);
        setDisable(true);
      }
      setIsUpdating(false);
    } else {
      setSaveFailures([]);
      setTotalToSave(suborders.length);
      setSaveSuccessIds([]);

      const postPromiseFunctionality = (syncedVariableHolder) => {
        totalSavedDispatch({ type: 'increment' });
        syncedVariableHolder.countdown--;
        if (!syncedVariableHolder.countdown) {
          if (syncedVariableHolder.failures.length) {
            setSaveFailures(syncedVariableHolder.failures);
          }
          setTimeout(() => {
            setTotalToSave(0);
            totalSavedDispatch({ type: 'reset' });
            if (!syncedVariableHolder.failures.length) {
              onComplete();
            }
          }, 2000);
        }
      };

      new Promise(() => {
        const syncedVariableHolder = {
          countDown: suborders.length,
          failures: [],
        };
        /* eslint-disable no-loop-func */
        for (const suborder of suborders) {
          const { suborderId } = suborder;

          if (usingBestOptionsToggle && mapOfSuborderIdsToErrorMessagesForBestOptions[suborderId] !== undefined) {
            // there was an error with this; print notification
            syncedVariableHolder.failures.push({ id: suborderId, error: mapOfSuborderIdsToErrorMessagesForBestOptions[suborderId], success: false });
            postPromiseFunctionality(syncedVariableHolder);
          } else {
            // able to update the suborder delivery
            const suborderFields = usingBestOptionsToggle ? bestOptionDeliveryFields : fields;

            new Promise(() => updateSuborderDelivery(suborderId, suborderFields, options)
              .then((res) => {
                if (!res.success) {
                  syncedVariableHolder.failures.push({ id: suborder.suborderId, ...res, error: res.message });
                } else {
                  saveSuccessIds.push(suborderId);
                  setSaveSuccessIds(saveSuccessIds);
                }
                setIsUpdating(false);
              })
              .catch((err) => {
                setIsUpdating(false);
                console.error(err);
                syncedVariableHolder.failures.push({ id: suborder.suborderId, error: err.message, success: false });
              })
              .finally(() => {
                setIsUpdating(false);
                postPromiseFunctionality(syncedVariableHolder);
              }));
          }
        }
        /* eslint-enable no-loop-func */
      });
    }
  };
  // for redeliveryArgs
  useEffect(() => {
    if (redeliveryArgs && !usingBestOptionsToggle) {
      if (disableSave) {
        setDisable(true);
      }
      if (!disableSave) {
        handleUpdateDelivery();
      }
    }
  }, [
    deliveryWindow,
    deliveryArea,
    fc,
    deliveryDate,
    usingBestOptionsToggle,
    isLoading,
    bestOptionDate,
    overrideDeliveryTime,
    overrideDeliveryDuration,
  ]);

  const retrieveBestOptionsFromDeliveryDate = async (inputDelivDate) => {
    setMapOfSuborderIdsToBestOptionsArray({});
    setIsLoading(true);

    const dateToUse = inputDelivDate || deliveryDate;

    const newMapOfSuborderIdsToBestOptionsArray = {};
    const newMapOfSuborderIdsToErrorMessagesForBestOptions = {};

    let atLeastOneExists = false;

    await Promise.all(
      suborders.map(async (suborder) => {
        const bestOptions = [];

        try {
          const suborderCoordsAndZip = hasCoordsAndZip
            ? { coords, zipCode }
            : await getCoordsForSuborder(suborder);

          const recipient = {
            coords: suborderCoordsAndZip.coords,
            zip: suborderCoordsAndZip.zipCode,
          };

          const lineitems = redeliveryArgs
            ? redeliveryArgs.lineItems
            : getNonDeletedLineItems(suborder.lineItems);

          const allowedTransportModes = getAllowedTransportModes(lineitems, productFeed);

          const delivery = {
            date: dateToUse,
          };

          const deliveryAreaOptionsResponse = await getDeliveryAreaOptions(
            recipient,
            lineitems,
            delivery,
          );

          if (!deliveryAreaOptionsResponse.success) {
            console.error({ deliveryAreaOptionsResponse });
            throw new Error('No delivery area options response available.');
          }

          const deliveryAreaOptions = deliveryAreaOptionsResponse.data;

          const { ranking, options } = deliveryAreaOptions;

          if (!ranking || !ranking.length) {
            console.warn({ deliveryAreaOptions });
            throw new Error('No delivery area options available.');
          }

          for (const deliveryAreaRanking of ranking) {
            const currentFc = deliveryAreaRanking.fc;
            const optionForRanking = options[currentFc.id]?.[deliveryAreaRanking.id]?.[dateToUse];

            const deliveryAreaChosenByRanking = deliveryAreas[deliveryAreaRanking.id];

            if (deliveryAreaChosenByRanking) {
              const { transportMode } = deliveryAreaChosenByRanking;

              if (
                optionForRanking &&
                optionForRanking.windows &&
                optionForRanking.windows.length &&
                allowedTransportModes.includes(transportMode)
              ) {
                const currentOption = {
                  deliveryArea: deliveryAreaRanking,
                  window: optionForRanking.windows[0],
                  fc: currentFc,
                };
                bestOptions.push(
                  convertOptionToOmsFriendlyOption(
                    currentOption,
                    deliveryAreaLookup,
                    deliveryAreas,
                  ),
                );
              }
            }
          }

          if (bestOptions.length === 0) {
            throw new Error('No delivery area options available.');
          }

          atLeastOneExists = true;

          newMapOfSuborderIdsToBestOptionsArray[suborder.suborderId] = bestOptions;
        } catch (badUpdateError) {
          console.warn(
            "Won't be updating this suborder due to reason",
            suborder,
            badUpdateError,
          );

          newMapOfSuborderIdsToErrorMessagesForBestOptions[suborder.suborderId] =
            badUpdateError.message;
        }
      }),
    );

    setDisable(!atLeastOneExists);

    setMapOfSuborderIdsToBestOptionsArray(newMapOfSuborderIdsToBestOptionsArray);
    setMapOfSuborderIdsToErrorMessagesForBestOptions(newMapOfSuborderIdsToErrorMessagesForBestOptions);

    setSaveFailures(
      Object.keys(newMapOfSuborderIdsToErrorMessagesForBestOptions).map((key) => ({
        id: key,
        error: newMapOfSuborderIdsToErrorMessagesForBestOptions[key],
        success: false,
      })),
    );

    setIsLoading(false);
  };

  useEffect(() => {
    if (redeliveryArgs) {
      if (redeliveryArgs.deliveryDate !== bestOptionDate) {
        setBestOptionDate(redeliveryArgs.deliveryDate);
      }
    }

    if (!usingBestOptionsToggle) {
      setSaveFailures([]);
    }

    if (usingBestOptionsToggle && (redeliveryArgs || bestOptionDate)) {
      retrieveBestOptionsFromDeliveryDate(bestOptionDate || undefined);
    }
  }, [usingBestOptionsToggle, bestOptionDate]);

  useEffect(() => {
    if (redeliveryArgs && redeliveryArgs.deliveryDate) {
      setDeliveryDate(redeliveryArgs.deliveryDate);
    }
    setFc(null);
    setDeliveryArea(null);
    setDeliveryWindow(null);

    if (usingBestOptionsToggle && redeliveryArgs && redeliveryArgs.deliveryDate) {
      retrieveBestOptionsFromDeliveryDate(redeliveryArgs.deliveryDate);
    }
  }, [redeliveryArgs?.deliveryDate]);

  useEffect(() => {
    if (redeliveryArgs && usingBestOptionsToggle) {
      retrieveBestOptionsFromDeliveryDate(redeliveryArgs.deliveryDate);
    }
  }, [redeliveryArgs?.lineItems]);

  // Use recipient coordinates and zip to fetch delivery areas, if available
  useEffect(() => {
    const getAreasfromCoords = async (coords, zipCode) => {
      const response = await getAreas(coords, zipCode); // API Call

      if (!response.success) {
        setAreasFromCoordsApi(null);
      } else {
        setAreasFromCoordsApi(response.areas);
      }
    };

    if (hasCoordsAndZip) {
      getAreasfromCoords(coords, zipCode);
    }
  }, [coords, zipCode, hasCoordsAndZip]);

  // set FC options
  useEffect(() => {
    let allFcOptions = [];
    const fcAreaCoordsOptions = [];
    const otherFcOptions = [];

    const createFcOption = (fc) => {
      return { value: fulfillmentCenters[fc].id.toString(), label: fulfillmentCenters[fc].name };
    };

    // Group FCs
    for (const fcOption in fulfillmentCenters) {
      const option = createFcOption(fcOption);
      const updateSingleDeliveryPermissionCheck =
        hasCoordsAndZip && (!newOrder || !redeliveryArgs) && !roleChecks.canUpdateDeliveryInUpdateDeliveryComponent(userGroups);
      if (areasFromCoordsApi) {
        if (areasFromCoordsApi.find((a) => a.fc.id === fulfillmentCenters[fcOption].id)) {
          fcAreaCoordsOptions.push(option);
        } else {
          if (updateSingleDeliveryPermissionCheck) {
            option.isDisabled = true;
          }
          otherFcOptions.push(option);
        }
      } else {
        if (updateSingleDeliveryPermissionCheck) {
          option.isDisabled = true;
        }
        otherFcOptions.push(option);
      }
    }

    allFcOptions = [
      {
        label: 'FCs matching recipient area coordinates',
        options: fcAreaCoordsOptions,
      },
      {
        label: 'Other FCs',
        options: otherFcOptions,
      },
    ];

    setFcOptions(allFcOptions);

    let currentFc = null;

    // set initially selected FC
    if (!redeliveryArgs) {
      for (const fcCategory in allFcOptions) {
        if (Object.prototype.hasOwnProperty.call(allFcOptions, fcCategory)) {
          currentFc = allFcOptions[fcCategory].options.find((fc) => Number(fc.value) === fcId);
          if (currentFc) {
            break;
          }
        }
      }
      setFc(currentFc);
    }
  }, [fulfillmentCenters, areasFromCoordsApi, fcId, userGroups, hasCoordsAndZip, newOrder, redeliveryArgs]);

  const getDeliveryAreaPrioritySorter = (area1, area2) => area1.priority - area2.priority;

  const getDeliveryAreasOrderedByPriority = () => {
    const arr = [];
    for (const area in deliveryAreas) {
      if (Object.prototype.hasOwnProperty.call(deliveryAreas, area)) {
        arr.push(deliveryAreas[area]);
      }
    }
    arr.sort(getDeliveryAreaPrioritySorter);

    return arr;
  };

  const getIdsOfAreasFromCoords = () => {
    if (areasFromCoordsApi !== null) {
      return areasFromCoordsApi.map((area) => area.id);
    }
    return null;
  };

  // Set delivery area options
  useEffect(() => {
    // Filter areas by current FC and Coordinates or just FC
    let allAreaOptions = [];
    const allowedTransportModeAreas = [];
    const otherAreaOptions = [];

    let areaFc;

    if (fc) {
      areaFc = Number(fc.value);
    } else {
      areaFc = fcId;
    }

    const createAreaOption = (area) => {
      if (area && area.isActive) {
        return {
          value: area.id,
          label: area.description ? area.description : area.id,
        };
      }
    };

    const lineItemsForDelivery = suborders
      ? suborders
          .map((suborder) => suborder.lineItems)
          .filter((lineItem) => !lineItem.isDeleted)
          .flat()
      : [];
    const allowedTransportModes = getAllowedTransportModes(lineItemsForDelivery, productFeed);

    const deliveryAreasOrderedByPriority = getDeliveryAreasOrderedByPriority();

    const idsOfAreasFromCoords = getIdsOfAreasFromCoords();

    for (const area of deliveryAreasOrderedByPriority) {
      if (area.fcId === areaFc) {
        const option = createAreaOption(area);

        if (option) {
          const allowedTransportModeCheck = allowedTransportModes.includes(area.transportMode);
          const isAppropriateWithRegardsToDistanceCheck = idsOfAreasFromCoords === null || idsOfAreasFromCoords.includes(area.id);
          if (allowedTransportModeCheck && isAppropriateWithRegardsToDistanceCheck) {
            allowedTransportModeAreas.push(option);
          } else {
            otherAreaOptions.push(option);
            if (!newOrder && !roleChecks.canSelectOtherDeliveryAreas(userGroups)) {
              option.isDisabled = true;
            }
          }
        }
      }
    }

    allAreaOptions = [
      {
        label: 'Available Delivery Areas',
        options: allowedTransportModeAreas,
      },
      {
        label: 'Other Areas',
        options: otherAreaOptions,
      },
    ];

    setAreaOptions(allAreaOptions);

    // Set selected area
    if (!redeliveryArgs) {
      if (subordersProps.deliveryArea) {
        let currentArea = null;

        for (const areaCategory of allAreaOptions) {
          currentArea = areaCategory.options.find((area) => area.value === Number(subordersProps.deliveryArea));
          if (currentArea) {
            break;
          }
        }

        if (currentArea) {
          setDeliveryArea(currentArea);
        } else {
          setDeliveryArea(null);
        }
      } else {
        setDeliveryArea(null);
      }
    }

    if (subordersProps.deliveryDate && deliveryDate === null) {
      setDeliveryDate(subordersProps.deliveryDate);
    }
  }, [fc, areasFromCoordsApi, fcId, deliveryAreas, subordersProps, userGroups, hasCoordsAndZip, newOrder]);

  // Set window options
  useEffect(() => {
    const getWindows = async () => {
      let area = null;
      if (deliveryArea) {
        area = deliveryArea.value;
      } else if (subordersProps.deliveryArea) {
        area = subordersProps.deliveryArea;
      }

      if (area && deliveryDate) {
        const result = await getDeliveryWindows(area, deliveryDate);

        if (result.success) {
          const windows = result.deliveryWindows;

          let options = [];
          const availableWindows = [];
          const windowsPastCutoff = [];
          const windowsPastStart = [];

          windows.forEach((option) => {
            const window = parseFlexibleDate(option.starts_at, option.ends_at, option, option.timezone);
            if (option.past_cutoff === false && option.past_start === false) {
              availableWindows.push(window);
            } else if (option.past_cutoff === true && option.past_start === false) {
              windowsPastCutoff.push(window);
            } else {
              windowsPastStart.push(window);
            }
          });

          options = [
            {
              label: 'Available Windows',
              options: availableWindows,
            },
            {
              label: 'Windows Past Cutoff',
              options: windowsPastCutoff,
            },
            {
              label: 'Windows Past Start',
              options: windowsPastStart,
            },
          ];

          setWindowOptions(options);
        }
      }
    };

    getWindows();
  }, [deliveryDate, deliveryArea, subordersProps]);

  // set selected window
  useEffect(() => {
    if (!!windowOptions.length && !redeliveryArgs) {
      if (subordersProps.window) {
        const availableOptions = windowOptions.find((el) => el.label === 'Available Windows');

        const selectedWindow = availableOptions.options.find((window) => {
          return window.label === subordersProps.window;
        });

        if (selectedWindow) {
          setDeliveryWindow(selectedWindow);
        } else {
          setDeliveryWindow(null);
        }
      } else {
        setDeliveryWindow(null);
      }
    }
  }, [windowOptions, subordersProps]);

  const handleBestOptionDateChange = async (datestr) => {
    setSaveFailures([]);
    setSelectedBestOption(null);
    setBestOptionDate(datestr);
  };

  const handleDateChange = (date, datestr) => {
    setDeliveryDate(datestr);
  };

  const handleAreaChange = (areaId) => {
    let currentArea = null;
    setDeliveryWindow(null);

    for (const areaCategory of areaOptions) {
      currentArea = areaCategory.options.find((area) => area.value === areaId);
      if (currentArea) {
        setDeliveryArea(currentArea);
        break;
      }
    }
  };

  const handleFcChange = (fcId) => {
    setDeliveryArea(null);
    setDeliveryWindow(null);
    for (const fcCategory in fcOptions) {
      if (Object.prototype.hasOwnProperty.call(fcOptions, fcCategory)) {
        const currentFc = fcOptions[fcCategory].options.find((fc) => fc.value === fcId);
        if (currentFc) {
          setFc(currentFc);
          break;
        }
      }
    }
  };

  const renderForm = () => (
    <>
      <div className={redeliveryArgs ? '' : 'card-body pl-0 pt-0'}>
        {!redeliveryArgs && (
          <div className="row">
            <div className="mb-3">
              {suborders ? <p>For suborders: {suborders.map((suborder) => suborder.suborderId).join(',')} </p> : <p>Create delivery: </p>}
            </div>
          </div>
        )}
        {!newOrder && (
          <div className="row">
            <div className="mb-3">
              <span style={{ verticalAlign: 'middle' }}>Best Option </span>
              <Switch
                style={{ display: 'inline-block', verticalAlign: 'middle' }}
                checked={usingBestOptionsToggle}
                onChange={(e) => setBestOptionToggle(e.target.checked)}
              />
            </div>
          </div>
        )}
        {usingBestOptionsToggle && (
          <>
            {!redeliveryArgs && <BestOptionDatePicker bestOptionDate={bestOptionDate} setBestOptionDate={handleBestOptionDateChange} />}
            {(redeliveryArgs || bestOptionDate) &&
              Object.keys(mapOfSuborderIdsToBestOptionsArray).length === 1 &&
              suborders.length &&
              mapOfSuborderIdsToBestOptionsArray[suborders[0].suborderId] !== undefined &&
              mapOfSuborderIdsToBestOptionsArray[suborders[0].suborderId]
                .sort((option1, option2) => getDeliveryAreaPrioritySorter(deliveryAreas[option1.areaId], deliveryAreas[option2.areaId]))
                .map((bestOption, index) => (
                  <div className={redeliveryArgs ? 'row' : 'mt-3'} key={`option${index}`}>
                    <DeliveryOptionSelectionComponent
                      fcName={bestOption.fc.name}
                      deliveryAreaName={bestOption.deliveryAreaName}
                      window={bestOption.windowDescription}
                      isSelected={selectedBestOption && selectedBestOption.deliveryAreaName === bestOption.deliveryAreaName}
                      setIsSelected={() => setSelectedBestOption(bestOption)}
                      onSelection={() => (redeliveryArgs ? setDeliveryFields(bestOption) : setBestOptionDeliveryFields(bestOption))}
                    />
                  </div>
                ))}
            {isLoading && <Spinner />}
          </>
        )}
        {!usingBestOptionsToggle && (
          <>
            <div className="row">
              <div className="mb-3">
                <Select
                  id="selectFC"
                  value={fc}
                  placeholder="Select Fulfillment Center..."
                  onChange={(option) => handleFcChange(option.value)}
                  options={fcOptions}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                />
              </div>
            </div>
            <div className="row">
              <div className="mb-3">
                <Select
                  id="selectDeliveryArea"
                  placeholder="Select Delivery Area..."
                  value={deliveryArea}
                  onChange={(option) => handleAreaChange(option.value)}
                  options={areaOptions}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  menuPortalTarget={document.body}
                />
              </div>
            </div>
            {!redeliveryArgs && (
              <div className="row">
                <div className="mb-3">
                  <Flatpickr
                    className="form-control"
                    value={deliveryDate}
                    onChange={(date, datestr) => handleDateChange(date, datestr)}
                    options={{ minDate: DateTime.now().toISO() }}
                  />
                </div>
              </div>
            )}
            {!addNewDeliveryWindow && (
              <div className="row">
                <div className="mb-3">
                  <Select
                    id="selectDeliveryWindow"
                    value={deliveryWindow}
                    placeholder="Select Delivery Window..."
                    onChange={(option) => {
                      setDeliveryWindow(option);
                    }}
                    options={windowOptions}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                  />
                </div>
              </div>
            )}

            {roleChecks.canUpdateDeliveryInUpdateDeliveryComponent(userGroups) && (
              <div className="row">
                <div className="mb-3">
                  <Checkbox
                    label="Add a custom delivery window"
                    checked={addNewDeliveryWindow}
                    onChange={(e) => setAddNewDeliveryWindow(e.target.checked)}
                  />
                </div>
              </div>
            )}
            {addNewDeliveryWindow && (
              <>
                <div className="row">
                  <label>Window start time</label>
                  <div className="mb-3">
                    <Flatpickr
                      className="window start time override"
                      value={overrideDeliveryTime}
                      onChange={(date) => {
                        setOverrideDeliveryTime(date[0]);
                      }}
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'h:i K',
                        time_24hr: false,
                      }}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="mb-3">
                    <TextInput
                      label="Window duration in hours"
                      onChange={(e) => setOverrideDeliveryDuration(e.target.value)}
                      name="windowDurationOverride"
                      value={overrideDeliveryDuration}
                      validate={(val) => parseInt(val, 10) && Number(val) > 0}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {!NETSUITE_INVENTORY_FEATURE_FLAG && !redeliveryArgs && fc && parseInt(fc.value, 10) !== fcId && (
          <div className="row">
            <div className="mb-3">
              What should we do with the inventory?
              <Select
                id="selectDallocateType"
                defaultValue={deallocateStrategies.find((x) => x.value === deallocateStrategy)}
                onChange={(event) => setDeallocateStrategy(event.value)}
                options={deallocateStrategies}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-2 mx-4">
        {!!saveSuccessIds.length && (
          <div>
            <p className="text-success mt-4">Update Successes</p>
            <ul>
              {saveSuccessIds.map((successId) => (
                <li className="text-success" key={successId}>{`${successId} successfully updated`}</li>
              ))}
            </ul>
          </div>
        )}

        {totalToSave > 0 && totalToSave === totalSaved && !saveFailures.length && <p className="text-success">Done!</p>}

        {!!saveFailures.length && (
          <div>
            <p className={redeliveryArgs ? 'text-danger' : 'text-danger mt-4'}>Update Failures</p>
            <ol>
              {saveFailures.map((f) => (
                <li className="text-danger" key={f.id}>{`${f.id}: ${f.error}`}</li>
              ))}
            </ol>
          </div>
        )}
        <br />
      </div>
      {!redeliveryArgs && (
        <div style={{ marginBottom: 20 }}>
          <div className="d-flex justify-content-end">
            <Button
              appearance="primary"
              isLoading={isUpdating}
              isL
              type="submit"
              className="btn btn-primary mx-1"
              disabled={disableSave}
              onClick={handleUpdateDelivery}
            >
              Update
            </Button>
            <Button type="button" className="btn btn-secondary" onClick={() => setShowChangeDelivery(false)}>
              {buttonText}
            </Button>
          </div>
        </div>
      )}
    </>
  );

  return useDialog ? (
    <Dialog
      title={isBulk ? 'Bulk Update' : 'Update Suborder Delivery'}
      isShown={showChangeDelivery}
      onCloseComplete={() => setShowChangeDelivery(false)}
      hasFooter={false}
      confirmLabel="Update"
    >
      {renderForm()}
    </Dialog>
  ) : (
    renderForm()
  );
};

UpdateDelivery.defaultProps = {
  suborders: [],
  fcId: null,
  onComplete: () => {},
  coords: null,
  zipCode: '',
  setDeliveryFields: () => {},
  useDialog: true,
  redeliveryArgs: null,
  setDisable: () => {},
};

UpdateDelivery.propTypes = {
  suborders: PropTypes.array,
  fcId: PropTypes.number,
  deliveryAreas: PropTypes.object.isRequired,
  showChangeDelivery: PropTypes.bool.isRequired,
  setShowChangeDelivery: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  coords: PropTypes.object,
  zipCode: PropTypes.string,
  newOrder: PropTypes.bool.isRequired,
  setDeliveryFields: PropTypes.func,
  useDialog: PropTypes.bool,
  redeliveryArgs: PropTypes.object,
  setDisable: PropTypes.func,
};

export default UpdateDelivery;
