/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import { CreditCard } from 'react-bootstrap-icons';
import { Alert } from 'evergreen-ui';
import { roleChecks } from '../../utilities/Role';
import { AuthContext } from '../../context/authContext';
import { REACT_APP_SHOPIFY_ADMIN_URL } from '../../config';

const ShopifyPaymentBreakDown = ({ order, setShowChargeOrder }) => {
  const { userGroups } = useContext(AuthContext);
  const transaction = order.shopifyTransactions;
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">
          <CreditCard size="1.5em" className="mr-2" /> <span>Payment Summary <img alt="shopify logo" src="https://avatars.githubusercontent.com/u/8085?s=200&v=4" width={20} /></span>
        </h4>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table">
            <tbody>
              {transaction.lineItems.map((line) => {
                const linePrice = line.lineItemPriceWithoutDiscountsApplied || line.originalUnitPriceSet.shopMoney.amount;
                return (
                  <tr key={`lineitem-${line.id}`}>
                    <td className={`px-0 py-1${linePrice < 0 ? ' text-decoration-line-through' : ''}`}>{line.title}</td>
                    <td className="px-0 py-1 text-right"> x{line.quantity}</td>
                    <td className={`px-0 py-1 text-right ${linePrice < 0 ? ' text-decoration-line-through' : ''}`}>
                      ${linePrice}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td className="px-0 py-1 border-top border-top-2">
                  <strong>Subtotal</strong>
                </td>
                <td className="px-0 py-1 border-top border-top-2" />
                <td className="px-0 py-1 text-right border-top border-top-2">
                  <strong>${transaction.subtotal.amount}</strong>
                </td>
              </tr>
              <tr>
                <td className="px-0 py-1">Discount</td>
                <td className="px-0 py-1 text-right"><span className="badge bg-info">{transaction.discountCode}</span></td>
                <td className="px-0 py-1 text-right">-${transaction.discount.amount}</td>
              </tr>
              <tr>
                <td className="px-0 py-1">Shipping</td>
                <td className="px-0 py-1" />
                <td className="px-0 py-1 text-right">${transaction.totalShipping.amount}</td>
              </tr>
              {transaction.taxes.map((tax, index) => (
                <tr key={`lineitem-${index}`}>
                  <td className="px-0 py-1">{!index && 'Taxes'}</td>
                  <td className="px-0 py-1">{tax.title} {tax.ratePercentage}%</td>
                  <td className={`px-0 py-1 text-right text-right${tax.price < 0 ? ' text-decoration-line-through' : ''}`}>
                    ${tax.price}
                  </td>
                </tr>
              ))}
              <tr>
                <td className="px-0 py-1 border-top border-top-2"><strong className="h3">Total</strong></td>
                <td className="px-0 py-1 border-top border-top-2" />
                <td className="px-0 py-1 text-right  border-top border-top-2"><strong className="h3">${transaction.total.amount}</strong></td>
              </tr>
              {transaction.salesTransaction.map((trans, index) => (
                <tr key={`lineitem-${trans.id}`}>
                  <td className={`px-0 py-1${trans.amountSet.shopMoney.amount < 0 ? ' text-decoration-line-through' : ''}`}><strong className="h3">{!index && 'Paid'}</strong></td>
                  <td className="px-0 py-1" />
                  <td className="px-0 py-1 text-right">
                    <strong className="h3">${trans.amountSet.shopMoney.amount}</strong>
                  </td>
                </tr>
              ))}
              {transaction.capturedTransaction.map((trans, index) => (
                <tr key={`lineitem-${trans.id}`}>
                  <td className={`px-0 py-1${trans.amountSet.shopMoney.amount < 0 ? ' text-decoration-line-through' : ''}`}><strong className="h3">{!index && 'Paid'}</strong></td>
                  <td className="px-0 py-1 text-right"><i style={{ color: 'gray', fontSize: 10, marginRight: 5 }}>{trans.processedAt} </i></td>
                  <td className={`px-0 py-1 text-right${trans.amountSet.shopMoney.amount < 0 ? ' text-decoration-line-through' : ''}`}>
                    <strong className="h3">${trans.amountSet.shopMoney.amount}</strong>
                  </td>
                </tr>
              ))}
              {transaction.refundsTransaction.map((trans, index) => (
                <tr key={`lineitem-${trans.id}`}>
                  <td className={`px-0 py-1${trans.totalRefundedSet.shopMoney.amount < 0 ? ' text-decoration-line-through' : ''}`}>{!index && 'Refunds'}</td>
                  <td className="px-0 py-1">{trans.note}</td>
                  <td className="px-0 py-1 text-right">
                    -${trans.totalRefundedSet.shopMoney.amount}
                  </td>
                </tr>
              ))}
              <tr>
                <td className="px-0 py-1">
                  <strong className="h3">Net Payment</strong>
                </td>
                <td className="px-0 py-1" />
                <td colSpan="2" className="px-0 py-1 text-right">
                  <span className="h3">${transaction.netPayment.amount}</span>
                </td>
              </tr>
              <tr>
                <td className="px-0 py-1">
                  <strong className="h3">Outstanding Balance</strong>
                </td>
                <td className="px-0 py-1" />
                <td colSpan="2" className="px-0 py-1 text-right">
                  <span className="h3">${transaction.totalOutstanding.amount}</span>
                </td>
              </tr>
              {transaction.giftCards && transaction.giftCards.length > 0 && transaction.giftCards.map((gc, index) => (
                <tr key={`giftcard-${gc.id}`}>
                  <td className="px-0 py-1">
                    {!index && 'Gift cards'}
                  </td>
                  <td className="px-0 py-1">
                    <a href={`${REACT_APP_SHOPIFY_ADMIN_URL}/gift_cards/${gc.id}`} rel="noreferrer" target="_blank">
                      {gc.reasons ? <><img src="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/gift-card-1WjvjslvRoGt.svg" width={30} alt="giftcard" /> {gc.reasons}</> : `gid://shopify/GiftCard/${gc.id}`}
                    </a>
                  </td>
                  <td className="px-0 py-1 text-right">
                    ${gc.balance}
                  </td>
                </tr>
              ))}
              {transaction.giftCardsToRecipient && transaction.giftCardsToRecipient.length > 0 && transaction.giftCardsToRecipient.map((gc, index) => (
                <tr key={`giftcard-${gc.id}`}>
                  <td className="px-0 py-1">
                    {!index && 'Gift cards to recipient'}
                  </td>
                  <td className="px-0 py-1">
                    <a href={`${REACT_APP_SHOPIFY_ADMIN_URL}/gift_cards/${gc.id}`} rel="noreferrer" target="_blank">
                      {gc.reasons ? <><img src="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/gift-card-1WjvjslvRoGt.svg" width={30} alt="giftcard" /> {gc.reasons}</> : `gid://shopify/GiftCard/${gc.id}`}
                    </a>
                  </td>
                  <td className="px-0 py-1 text-right">
                    ${gc.balance}
                  </td>
                </tr>
              ))}
              {transaction?.refundPending &&
                <tr>
                  <td colSpan="3" className="px-0 py-1">
                    <Alert
                      intent="none"
                      title="Refund processing in Shopify"
                      marginBottom={32}
                    >
                      Order will update when refund is processed in Shopify.
                    </Alert>
                  </td>
                </tr>}
            </tbody>
            <tbody>
              <tr>
                <td colSpan="3" align="center">
                  {roleChecks.canRefundOrIssueCreditsOnDirectlyToOrder(userGroups) && (
                    <>
                      {roleChecks.canViewChargeOrderButton(userGroups) && !!Number(transaction.totalOutstanding.amount) && (
                        <button
                          className="mr-2 btn btn-success"
                          onClick={() => {
                            setShowChargeOrder(true);
                          }}
                        >
                          Charge Order
                        </button>
                      )}
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ShopifyPaymentBreakDown;
