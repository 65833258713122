import { NETSUITE_INVENTORY_FEATURE_FLAG } from '../../../../../config';
import { getSingleInventory } from '../../../../../services/AggregateService';
import formatDateString from '../../common/formatDateString';
import formatSpoilDate from '../../common/formatSpoilDate';
import getNoImageFoundImageUrl from './getNoImageFoundImageUrl';

const getSelectedInventoryRecordData = async (lotId, productFeed, fulfillmentCenters) => {
  try {
    const res = await getSingleInventory(lotId);
    const productFromFeed = NETSUITE_INVENTORY_FEATURE_FLAG ? productFeed[res.sku] : productFeed[res.product_id.productSKU];
    const singleView = NETSUITE_INVENTORY_FEATURE_FLAG ?
      {
        adjustmentsSummary: '',
        adjustments: [],
        changelogs: '',
        imageUrl: productFromFeed?.imageUrl || getNoImageFoundImageUrl(),
        externalLotId: res.lot_id,
        internalLotId: res.id,
        netsuiteInventoryNumberId: res.external_id,
        productName: res.product_name,
        productSku: res.sku,
        status: NETSUITE_INVENTORY_FEATURE_FLAG ? ((res.inbound_quantity > 0) ? 'On Order' : 'Received') : res.shipment_status,
        fcId: res.fc_id,
        classification: res.product_classification,
        details: [
          {
            order: 1,
            column: 'Netsuite Lot ID',
            value: res.lot_id,
          },
          {
            order: 5,
            column: 'Vendor',
            value: res.vendor,
          },
          {
            order: 8,
            column: 'Arrival Date',
            value: formatDateString(res.arrival_date),
          },
          {
            order: 9,
            column: 'Start Date',
            value: formatDateString(res.start_fulfillment_date),
          },
          {
            order: 10,
            column: 'Expiration Date',
            value: formatSpoilDate(res.expiration_date),
          },
          {
            order: 11,
            column: 'Available for Sale',
            value: res.available_for_sale_qty,
          },
          {
            order: 12,
            column: 'On Hand',
            value: res.on_hand_qty,
          },
          {
            order: 3,
            column: 'Fulfillment Center',
            value: fulfillmentCenters[res.fc_id].name,
          },
          {
            order: 4,
            column: 'Product',
            value: res.product_name,
          },
        ],
      }
      :

      {
        adjustmentsSummary: res.summary,
        adjustments: res.adjustments,
        changelogs: res.delivery_memo,
        imageUrl: productFromFeed?.imageUrl || getNoImageFoundImageUrl(),
        externalLotId: res.netsuite_lot_id,
        internalLotId: res.delivery_id,
        productName: res.product_id.name,
        productSku: res.product_id.productSKU,
        status: res.shipment_status,
        fcId: res.distributionpoint_id,
        classification: res.product_id.classification,
        productFromResponse: res.product_id,
        details: [
          {
            order: 1,
            column: 'Netsuite Lot ID',
            value: res.netsuite_lot_id,
          },
          {
            order: 2,
            column: 'ADMIN Lot ID',
            value: res.delivery_id,
          },
          {
            order: 5,
            column: 'Vendor',
            value: res.vendor,
          },
          {
            order: 8,
            column: 'Arrival Date',
            value: formatDateString(res.arrival_date),
          },
          {
            order: 9,
            column: 'Start Date',
            value: formatDateString(res.start_date),
          },
          {
            order: 10,
            column: 'Spoil Date',
            value: formatSpoilDate(res.spoil_date),
          },
          {
            order: 11,
            column: 'Available for Sale',
            value: res.stock,
          },
          {
            order: 12,
            column: 'Count On Hand',
            value: res.count_on_hand,
          },
          {
            order: 3,
            column: 'Fulfillment Center',
            value: res.fulfillmentcenter_name,
          },
          {
            order: 4,
            column: 'Product',
            value: res.product_id.name,
          },
          {
            order: 6,
            column: 'Last Netsuite Transaction',
            value: res.purchase_order,
          },
          {
            order: 7,
            column: 'Purchase Stock',
            value: res.stock_initial,
          },
          {
            order: 13,
            column: 'Adjustments Made',
            value: res.adjustments.length,
          },
        ],
      };

    return singleView;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Had error while trying to get single view', { error });

    return null;
  }
};

export default getSelectedInventoryRecordData;
