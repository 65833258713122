/* eslint-disable prefer-const */
/* eslint-disable no-console */
import axios from 'axios';
import { path } from 'ramda';
import { useQuery } from 'react-query';
import { getReasonType } from '../components/order/helpers/getReasonType';
import { ORDER_MANAGEMENT_SERVICE_BASE_URL, BRAINTREE_DEFAULT_TOKEN } from '../config';

const isFloral = /^FLRL-[BKDT]-/;
const isPlant = /^NF-(K|PLNT)-/;

/**
 * Get the order.
 *
 * @param {number} orderId Order Id
 * @returns {{order: Object, success: boolean, errorMessage: string}} The fetched order
 */
export const getOrder = async (orderId) => {
  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/${orderId}`, { validateStatus: false, timeout: 10000 });
    const order = path(['data', 'order'], data) || {};
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    const errorMessage = success ? undefined : path(['message'], data) || '';
    return {
      order,
      success,
      errorMessage,
    };
  } catch (e) {
    return {
      order: {},
      success: false,
      errorMessage: e.toString(),
    };
  }
};

export const hardSyncOrder = async (orderId) => {
  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/${orderId}/netsuite/sync`);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return {
      order: {},
      success: false,
      errorMessage: e.toString(),
    };
  }
};

/**
 *
 * @param {*} files array of files that follows format in https://urbanstems.atlassian.net/wiki/spaces/OMS/pages/165609838/Bulk+Order+CSV+Template
 * @param {*} requestId string; if exists then this call will be for ingestion. if it doesn't exist then this call will be for preview
 * @returns response indicating success or failure
 */
export const ingestBulkOrder = async (files, requestId) => {
  try {
    const formData = new FormData();

    if (files && files[0]) {
      formData.append('file', files[0]);
    }

    if (requestId) {
      formData.append('requestId', requestId);
    }

    const { data: responseData } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/bulk`, formData);
    const { status, data } = responseData;
    const success = status.toUpperCase() === 'SUCCESS';

    return {
      data,
      success,
    };
  } catch (e) {
    return {
      order: {},
      success: false,
    };
  }
};

/**
 *
 * @param {*} requestId string; checks s3 for state of request ID for bulk orders
 * @returns response showing acceptable and bad orders of bulk order request
 */
export const getBulkUploadResponse = async (requestId) => {
  try {
    const { data: callResponse } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/bulk/response/${requestId}`);
    const { status, data } = callResponse;
    const success = status.toUpperCase() === 'SUCCESS';

    return { data, success };
  } catch (e) {
    return {
      error: e,
      success: false,
    };
  }
};

/**
 * Get the suborder.
 *
 * @param {number} suborderId Suborder Id
 * @returns {{order: Object, success: boolean}} The fetched order
 */
export const getSuborder = async (suborderId) => {
  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}`);
    const suborder = path(['data', 'suborder'], data) || {};
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      suborder,
      success,
    };
  } catch (e) {
    return {
      suborder: {},
      success: false,
    };
  }
};

/**
 * React Query custom hook for fc suborders
 * @param {*} fcId
 * @param {*} date
 */
export const useGetFCSuborders = (fcId, date) =>
  useQuery(
    ['fulfillmentSuborders', { fcId, date }],
    async () => {
      const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/fulfillmentCenters/${fcId}/suborders/${date}`);
      const { suborders } = data.data;
      return suborders;
    },
    { retry: false },
  );

/**
 *  Update suborder
 *
 * @param {string} suborderId Suborder id
 * @param {object} suborder Suborder to update with
 * @returns {{suborder: Object, success: boolean}} The fetched order
 */
export const updateSuborder = async (suborderId, suborder) => {
  if (!suborderId || !suborder) {
    return { success: true, suborder };
  }
  try {
    const patchData = { fields: suborder };
    const { data } = await axios.patch(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}`, patchData);
    const savedSuborder = path(['data', 'savedFields'], data) || {};
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      savedFields: savedSuborder,
      success,
    };
  } catch (e) {
    return {
      success: false,
      suborder,
      message: e?.response?.data?.message,
    };
  }
};

/**
 * Update order recipient information
 *
 * @param {string} suborderId Suborder to update recipient data for
 * @param {object} recipientData Recipient data to update
 * @returns {{recipient: Object, success: boolean}} The newly updated recipient
 */
export const updateRecipient = async (suborderId, recipientData) => {
  try {
    const recipient = {
      ...recipientData,
      coords: {
        lat: Number(recipientData.coords.lat),
        lng: Number(recipientData.coords.lng),
      },
    };

    const patchData = { fields: recipient };
    const { data } = await axios.patch(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/recipient`, patchData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 * Generate new coordinates for a recipient address change
 *
 * @param {object} recipient Recipient whose address is being updated
 * @returns {{coordinates: Object, success: boolean, addressComponents: array}}
 */
export const generateNewCoords = async (recipient) => {
  try {
    const recipientAddress = `${recipient.address1} ${recipient.address2} ${recipient.city} ${recipient.state} ${recipient.zipCode}`.replace('#', '');
    const instance = axios.create();
    instance.defaults.headers.common = {};

    const { data } = await instance.get(`https://urbanstems.com/geocode?a=${recipientAddress}`);
    const results = path(['results'], data) || {};
    const coordinates = results[0].geometry.location || {};
    const addressComponents = results[0].address_components;
    const success = (path(['status'], data) || '').toUpperCase() === 'OK';
    return {
      success,
      coordinates,
      addressComponents,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Update Order
 *
 * @param {string} orderId Order id
 * @param {object} order Order to update with
 * @returns {{suborder: Object, success: boolean}} The fetched order
 */
export const updateOrder = async (orderId, order) => {
  if (!orderId || !order) {
    return { success: true, order };
  }
  try {
    const patchData = { fields: order };
    const { data } = await axios.patch(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/${orderId}`, patchData);
    const savedOrder = path(['data', 'order'], data) || {};
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      order: savedOrder,
      success,
    };
  } catch (e) {
    return { success: false, order };
  }
};

/**
 *  Create batch
 *
 * @param {string} name Batch name
 * @param {string} fcId FC id for batch
 * @param {array} suborders Suborder to update with
 * @returns {{batch: Object, success: boolean}} The created batch
 */
export const createBatch = async (name, fcId, suborders) => {
  if (!fcId || !suborders || !suborders.length) {
    return { success: false, suborders };
  }
  try {
    // sort suborders by type
    // check if suborder has a lineItem which is primary product then sort by primary products SKU

    suborders.sort((a, b) => {
      let skuA = null;
      let skuB = null;
      for (const lineA of a.lineItems) {
        if (lineA.components && lineA.components.length) {
          skuA = lineA.components.find((x) => x.isPrimaryProduct) || lineA.sku;
          break;
        }

        if (lineA.isPrimaryProduct) {
          skuA = lineA.sku;
          break;
        }
      }

      for (const lineB of b.lineItems) {
        if (lineB.components && lineB.components.length) {
          skuB = lineB.components.find((x) => x.isPrimaryProduct) || lineB.sku;
          break;
        }

        if (lineB.isPrimaryProduct) {
          skuB = lineB.sku;
          break;
        }
      }

      if (skuA < skuB) {
        return -1;
      }
      if (skuA > skuB) {
        return 1;
      }

      return 0;
    });

    const subordersMaps = [{}];
    let currSlot = 1;
    const currBatch = 0;
    suborders.forEach((suborder, i) => {
      const slotMapForSuborder = [];
      // line item
      suborder.lineItems.forEach((lineItem) => {
        // if lineitem has components and is not a double or triple, assign slots to its components instead of it
        if (lineItem.components && lineItem.components.length && !(lineItem.components.length === 1 && lineItem.components[0].quantity > 1)) {
          lineItem.components.forEach((component, componentIndex) => {
            if (isFloral.test(component.sku) || isPlant.test(component.sku)) {
              slotMapForSuborder.push({
                lineItemIndex: `${lineItem.index}.${componentIndex}`,
                slot: `${currSlot}`,
              });
              currSlot++;
            }
          });
        } else if (isFloral.test(lineItem.sku) || isPlant.test(lineItem.sku)) {
          slotMapForSuborder.push({
            lineItemIndex: `${lineItem.index}`,
            slot: `${currSlot}`,
          });
          currSlot++;
        }
      });

      subordersMaps[currBatch][suborder.suborderId] = {
        batchIndex: i,
        lineItems: slotMapForSuborder,
      };
    });

    const postData = { name, fcId: parseInt(fcId), subordersMap: subordersMaps[currBatch] };
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches`, postData);
    const savedBatch = path(['data', 'batch'], data) || {};
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    const subordersAdded = path(['data', 'subordersAdded'], data) || {};
    return {
      order: savedBatch,
      success,
      subordersAdded,
    };
  } catch (e) {
    const message = e?.response?.data?.message ? e.response.data.message : e.message;
    return { success: false, suborders, message };
  }
};

/**
 *  Get batch
 *
 * @param {string} batchId id for batch
 * @returns {{success: boolean}} Whether or not the batch was successfully fetched
 */
export const getBatch = async (batchId) => {
  if (!batchId) {
    return { success: false };
  }
  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}`);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    const batch = path(['data', 'batch'], data) || {};
    return {
      success,
      batch,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Delete batch
 *
 * @param {string} batchId id for batch
 * @returns {{success: boolean}} Whether or not the batch was deleted
 */
export const deleteBatch = async (batchId) => {
  if (!batchId) {
    return { success: false };
  }
  try {
    const { data } = await axios.delete(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}`);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

/**
 *  Update batch status
 *
 * @param {string} batchId id for batch
 * @param {string} status new batch status
 * @returns {{success: boolean}} Whether or not the batch was updated
 */
export const updateBatchStatus = async (batchId, status) => {
  if (!batchId || !status) {
    return { success: false };
  }
  try {
    const { data } = await axios.patch(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}`, { fields: { status } });
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return { success: false, error: e.response.data };
  }
};

/**
 *  Update batch ups-pld status
 *
 * @param {string} batchId id for batch
 * @param {string} status new batch status
 * @returns {{success: boolean}} Whether or not the batch was updated
 */
export const generateAndSendPld = async (batchId) => {
  if (!batchId) {
    throw new Error('batchId is missing');
  }
  try {
    const postData = { id: batchId };
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/ups-pld`, postData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
      data: data.data,
    };
  } catch (e) {
    if (axios.isAxiosError) {
      return { success: false, error: e.response.data };
    }
    return { success: false, error: e.message };
  }
};

/**
 *  Update batch suborder status
 *
 * @param {string} batchId id for batch
 * @param {string} status new suborder status
 * @returns {{success: boolean}} Whether or not the batch was updated
 */
export const updateBatchSuborderStatus = async (batchId, status) => {
  if (!batchId || !status) {
    return { success: false };
  }
  try {
    const { data } = await axios.patch(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}/suborders`, { status });
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Remove suborders from batch
 *
 * @param {string} batchId id for batch
 * @param {array} suborderIds suborder ids to remove
 * @returns {{success: boolean}} Whether or not the batch was deleted
 */
export const removeSubordersFromBatch = async (batchId, suborderIds) => {
  if (!batchId || !suborderIds) {
    return { success: false };
  }
  try {
    const { data } = await axios({
      method: 'DELETE',
      url: `${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}/suborders`,
      data: { suborderIds },
    });
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    const { subordersRemoved } = data.data;
    return {
      success,
      subordersRemoved,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Create delivery
 *
 * @param {string} suborderId id for suborder
 * @returns {{success: boolean}} Whether or not the delivery was created
 */
export const createDelivery = async (suborderId, overrideServiceErrors = false) => {
  if (!suborderId) {
    return { success: false };
  }
  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/delivery`, {
      overrideServiceErrors,
    });
    const savedDelivery = path(['data', 'delivery'], data) || {};
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
      delivery: savedDelivery,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Cancel delivery
 *
 * @param {string} suborderId id for suborder
 * @returns {{success: boolean}} Whether or not the delivery was cancelled
 */
export const cancelDelivery = async (suborderId) => {
  if (!suborderId) {
    return { success: false };
  }
  try {
    const { data } = await axios.delete(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/delivery`);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Cancel order
 *
 * @param {string} orderId id for suborder
 * @param {string} refundStrategy default: "full"
 * @param {string} deallocateStrategy "delete" | "care" | "reconcile"
 * @returns {{success: boolean}} Whether or not the delivery was created
 */
export const cancelOrder = async (orderId, refundStrategy, deallocateStrategy, reasons) => {
  if (!orderId) {
    return { success: false };
  }
  try {
    const requestData = {
      data: {
        options: {
          refundStrategy: refundStrategy || 'full',
          deallocateStrategy: deallocateStrategy || 'delete',
          reasons,
        },
      },
    };

    const { data } = await axios.delete(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/${orderId}`, requestData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    const errorMessage = e.response?.data?.message || e.message || 'Unknown error occurred';
    return { success: false, message: errorMessage };
  }
};

/**
 *  Refund order
 *
 * @param {string} orderId id for order
 * @param {number | null} amount Refund amount if partial refund or null if full refund
 * @param {object} furtherPostData can include reasons in form of "reasons" or "suborderReasons" or "lineItemReasons"
 * @returns {{success: boolean, message: string}} Whether or not refund was processed and error message from api if any
 */
export const refundOrder = async (orderId, amount, furtherPostData) => {
  if (!orderId) {
    return { success: false };
  }

  const postData = { ...furtherPostData };

  if (amount) {
    postData.amount = amount;
  }

  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/${orderId}/refund`, postData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return {
      success: false,
      message: e?.response?.data?.message,
    };
  }
};

/**
 *  Update suborder delivery information
 *
 * @param {string} suborderId id for suborder
 * @param {object} fields Fields to update Eg. {areaId: 4} to update delivery area
 * @param {object} options deallocateStrategy, useCareBuffer
 * @returns {{success: boolean}} Whether or not the delivery was updated
 */
export const updateSuborderDelivery = async (suborderId, fieldData, options) => {
  if (!suborderId) {
    return { success: false };
  }
  try {
    const patchData = { fields: fieldData, options };
    const { data } = await axios.patch(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/delivery`, patchData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

export const redistributeFulfillment = async (suborderIds, newDeliveryDate, forceNewFc, deallocateStrategy) => {
  try {
    const postData = { suborderIds, newDeliveryDate, forceNewFc, deallocateStrategy };
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/redistributeFulfillment`, postData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
      data: data.data,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

/**
 *  Create suborder redelivery
 *
 * @param {string} suborderId id for suborder
 * @returns {{success: boolean}} Whether or not the delivery was created
 */
export const createSuborderRedelivery = async (suborderId, reasons, lineItemReasons) => {
  if (!suborderId) {
    return { success: false };
  }
  try {
    const postData = { reasons, lineItemReasons };
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/redelivery`, postData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Create suborder exception
 *
 * @param {string} suborderId id for suborder
 * @returns {{success: boolean}} Whether or not the exception was created
 */
export const createSuborderException = async (suborderId, reason, sku) => {
  if (!suborderId) {
    return { success: false };
  }
  try {
    const postData = { reason, type: await getReasonType(reason), sku };
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/exception`, postData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
      data: { ...postData, suborderId },
    };
  } catch (e) {
    return { success: false, data: { suborderId, reason, sku } };
  }
};

/**
 *  Remove suborder exception
 *
 * @param {string} suborderId id for suborder
 * @returns {{success: boolean}} Whether or not the exception was deleted
 */
export const removeSuborderException = async (suborderId) => {
  if (!suborderId) {
    return { success: false };
  }
  try {
    const { data } = await axios.delete(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/exception`);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Add new suborder to order
 *
 * @param {string} orderId Id for order
 * @param {object} lineItem Line item for new suborder to add to order
 * @returns {{success: boolean}} Whether or not the suborder was successfully added
 */
export const addNewSuborderToOrder = async (orderId, lineItem, reasons, newSuborderIsCharged) => {
  if (!orderId) {
    return { success: false };
  }

  // transform lineItem format
  const postData = {
    lineItems: [
      {
        sku: lineItem.sku,
        name: lineItem.name,
        unitPrice: lineItem.price,
        isPrimaryProduct: lineItem.isPrimary,
        images: {
          large: lineItem.imageUrl,
          thumbnail: lineItem.imageThumbUrl,
        },
        quantity: 1,
        taxRate: 0,
        taxAmount: 0,
        price: newSuborderIsCharged ? lineItem.price : 0,
        originalPrice: lineItem.price,
        discountAmount: 0,
        components: lineItem.kit,
      },
    ],
    updateTotals: newSuborderIsCharged ? 'true' : 'false',
  };

  if (reasons) {
    postData.reasons = reasons;
  }

  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/${orderId}/suborders`, postData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Add new suborder to order for resolution wizard
 *
 * @param {string} orderId Id for order
 * @param {object} postData suborder with redelivery info
 * @returns {{success: boolean}} Whether or not the suborder was successfully added
 */
export const addNewSuborderToOrderForResolution = async (orderId, postData) => {
  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/${orderId}/suborders`, postData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
      newSuborder: data.data.newSuborder,
    };
  } catch (e) {
    return {
      success: false,
      message: e?.response?.data?.message,
    };
  }
};

export const shopifyRedelivery = async (suborderId, postData) => {
  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/shopify-redelivery`, postData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
      newSuborder: data.data.newSuborder,
    };
  } catch (e) {
    return {
      success: false,
      message: e?.response?.data?.message,
    };
  }
};

/**
 *  Add new line item to suborder
 *
 * @param {string} suborderId Id for suborder
 * @param {object} lineItem Line item for new suborder to add to suborder
 * @returns {{success: boolean}} Whether or not the lineitem was successfully added
 */
export const addLineItemToSuborder = async (suborderId, lineItem, reasons, isFree) => {
  if (!suborderId) {
    return { success: false };
  }

  // transform lineItem format
  const postData = {
    lineitem: {
      images: {
        large: lineItem.imageUrl,
        thumbnail: lineItem.imageThumbUrl,
      },
      quantity: 1,
      price: isFree ? 0 : lineItem.price,
      name: lineItem.name,
      sku: lineItem.sku,
      isPrimaryProduct: lineItem.isPrimary,
      components: lineItem.kit,
      originalPrice: lineItem.price,
      discountAmount: 0,
    },
    reasons,
    isFree,
    updateTotals: isFree ? 'false' : 'true',
  };

  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/lineitems`, postData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Remove Line Item from Suborder
 *
 * @param {string} suborderId Id for suborder
 * @param {number} lineItemIndex Line item index
 * @returns {{success: boolean}} Whether or not line item was successfully deleted
 */
export const removeLineItemFromSuborder = async (suborderId, lineItemIndex, deallocateStrategy, reasons) => {
  if (!suborderId) {
    return { success: false };
  }

  try {
    const requestData = {
      data: {
        deallocateStrategy,
        reasons,
      },
    };

    const { data } = await axios.delete(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/lineItems/${lineItemIndex}`, requestData);

    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Refund Line Item
 *
 * @param {string} suborderId Id for suborder
 * @param {array} lineItems Line items array
 * @returns {{success: boolean}} Whether or not line item was successfully refunded
 */
export const refundLineItem = async (suborderId, lineItems, reasons) => {
  if (!suborderId) {
    return { success: false };
  }

  const lineItemData = lineItems.map((lineItem) => {
    return (({ index, quantity, sku }) => ({ index, quantity, sku }))(lineItem);
  });

  const postData = { lineItems: lineItemData, reasons };

  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/lineItems/refund`, postData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Issue Credits
 *
 * @param {string} orderId id for order
 * @param {number} amount Credit amount to issue for the order
 * @param {string} emailAddress Email address of creditor
 * @param {object} furtherPostData further post data for issuing credit; can include reasons, suborderReasons, lineItemReasons
 * @returns {{success: boolean}} Whether or not credit was issued
 */
export const issueCredits = async (orderId, amount, emailAddress, furtherPostData) => {
  if (!orderId) {
    return { success: false };
  }

  const postData = {
    ...furtherPostData,
  };

  if (amount) {
    postData.amount = Number(amount);
    postData.emailAddress = emailAddress;
  }

  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/${orderId}/credits`, postData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 * Get customer by id or email
 *
 * @param {string} customerId Customer id or email
 * @returns {{success: boolean, customer: Object, errorMessage: string}} Customer object
 */

export const getCustomer = async (customerId) => {
  if (!customerId) {
    return { success: false };
  }

  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/customers/${customerId}/`, { validateStatus: false, timeout: 10000 });

    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    const customer = path(['data', 'customer'], data) || {};
    const errorMessage = success ? undefined : path(['message'], data) || '';
    return {
      success,
      customer,
      errorMessage,
    };
  } catch (e) {
    return {
      success: false,
      errorMessage: e.toString(),
    };
  }
};

/**
 *  Get orders by customer
 *
 * @param {string} customerId Customer id or email
 * @returns {{success: boolean, orders: Array}} Orders array
 */

export const getOrdersByCustomer = async (customerId) => {
  if (!customerId) {
    return { success: false };
  }

  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/customers/${customerId}/orders`);

    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    const orders = path(['data', 'orders'], data) || {};

    return {
      success,
      orders,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Get credits by customer
 *
 * @param {string} customerId Customer id or email
 * @returns {{success: boolean, credits: Array}} Credits array
 */

export const getCreditsByCustomer = async (customerId) => {
  if (!customerId) {
    return { success: false };
  }

  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/customers/${customerId}/credits`);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    const credits = path(['data'], data) || {};
    return {
      success,
      credits,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Create New Order
 *
 * @param {object} order Skeleton order
 * @returns {{success: boolean, order: Object}} Whether or not the order was created and new order
 */
export const createNewOrder = async (order) => {
  if (!order) {
    return { success: false };
  }

  const postData = { order };

  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/skeleton`, postData);
    const savedOrder = path(['data', 'skeletonOrder'], data) || {};
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
      order: savedOrder,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Send bloomreach event or email
 *
 * @returns {{success: boolean, message: string}} Whether or not resend succeeded and error message from api if fail
 */
export const sendBloomreachEventOrEmail = async (postData) => {
  if (!postData || !postData.orderId || !postData.eventType) {
    return { success: false };
  }

  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/${postData.orderId}/send-lifecycle-event`, postData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return {
      success: false,
      message: e?.response?.data?.message,
    };
  }
};

/**
 *  Get audit log entity
 *
 * @param {string} id
 * @param {string} type
 * @returns {{success: boolean, auditLog: Object}}
 */
export const getAuditLog = async (entity, id) => {
  if (!id || !entity) {
    return { success: false };
  }

  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/auditlogs/${entity}/${id}`);

    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    const auditLogEntries = path(['data', 'auditLogEntries'], data) || {};

    return {
      success,
      auditLogEntries,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Cancel sticky sub
 *
 * @param {string} orderId
 * @returns {{success: boolean, stopSubscriptionResponse: Object}}
 */
export const cancelStickySubscription = async (orderId) => {
  if (!orderId) {
    return { success: false };
  }

  try {
    const { data } = await axios.delete(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/${orderId}/subscription`);

    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    const stopSubscriptionResponse = path(['data', 'stopSubscriptionResponse'], data) || {};

    return {
      success,
      stopSubscriptionResponse,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Get related subscription orders
 *
 * @param {string} orderId
 * @returns {{success: boolean, orderIds: string[]}}
 */
export const getRelatedSubscriptionOrders = async (orderId) => {
  if (!orderId) {
    return { success: false };
  }

  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/orders/${orderId}/related-subscription-orders`);

    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    const orderIds = path(['data', 'orderIds'], data) || {};

    return {
      success,
      orderIds,
    };
  } catch (e) {
    return { success: false };
  }
};

/**
 *  Cancel suborder / remove suborder from order
 *
 * @param {string} suborderId id for suborder
 * @param {boolean} updateTotals Whether or not to update totals
 * @returns {{success: boolean, message: string}} Whether or not resend succeeded and error message from api if fail
 */
export const cancelSuborder = async (suborderId, updateTotals, deallocateStrategy, reasons) => {
  if (!suborderId) {
    return { success: false };
  }

  const requestData = {
    data: {
      options: {
        deallocateStrategy: deallocateStrategy || 'delete',
        reasons,
      },
    },
  };

  try {
    const { data } = await axios.delete(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}?updateTotals=${updateTotals}`, requestData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return {
      success: false,
    };
  }
};

/**
 * @returns TODO: this will become an API call that directly fetches from OMS (insert ticket num here)
 */
export const getSuborderStatusMap = () => {
  return {
    Received: 1,
    Batched: 10,
    BatchWorkorderPrinted: 20,
    Picking: 30,
    PickedAndQCd: 40,
    AtTable: 50,
    NotecardPrinted: 55,
    SuborderPrinted: 60,
    Packed: 70,
    BatchCompleted: 80,
    OnPallet: 90,
    AwaitingPickup: 100,
    LeftFulfillmentCenter: 105,
    InTransit: 110,
    OutForDelivery: 115,
    Delivered: 120,
    Cancelled: 999,
  };
};

/**
 *  Replace lineitem in suborder
 *
 * @param {string} suborderId Id for suborder
 * @param {object} newLineItem New line item details
 * @param {object} reason Reason for the upgrade
 * @param {object} oldLineItem Sku, quantity, reason for deleting old line item
 * @param {boolean} updateTotals Whether or not to update totals
 * @param {string} deallocateStrategy How to handle inventory adjustments
 * @param {boolean} ignoreInventoryAvailability Whether or not to ignore lack of inventory
 * @returns {{success: boolean}} Whether or not line item was successfully deleted and replaced
 */
export const replaceLineItemInSuborder = async (
  suborderId,
  newLineItemDetails,
  reason,
  oldLineItem,
  oldLineItemPrice,
  updateTotals,
  deallocateStrategy,
  ignoreInventoryAvailability,
) => {
  if (!suborderId || !newLineItemDetails || !oldLineItem) {
    return { success: false };
  }

  // transform lineItem format
  const lineItemToAddDetails = {
    images: {
      large: newLineItemDetails.imageUrl,
      thumbnail: newLineItemDetails.imageThumbUrl,
    },
    quantity: Number(newLineItemDetails.quantity) ? Number(newLineItemDetails.quantity) : oldLineItem.quantity,
    price: oldLineItemPrice || newLineItemDetails.price,
    name: newLineItemDetails.name,
    sku: newLineItemDetails.sku,
    isPrimaryProduct: newLineItemDetails.isPrimary,
    components: newLineItemDetails.kit,
    originalPrice: newLineItemDetails.price,
    discountAmount: 0,
  };

  if (newLineItemDetails.components) {
    lineItemToAddDetails.components = newLineItemDetails.components;
  }

  try {
    const requestData = {
      lineItemToAddDetails,
      lineItemToAddReasons: reason,
      lineItemToRemoveDetails: oldLineItem,
      options: {
        updateTotals: updateTotals || false,
        deallocateStrategy: deallocateStrategy || 'delete',
        ignoreInventoryAvailability: ignoreInventoryAvailability || false,
      },
    };

    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/lineItems/replace`, requestData);

    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    return {
      success,
    };
  } catch (e) {
    return {
      success: false,
      message: e?.response?.data?.message ? e.response.data.message : e.message,
    };
  }
};

/**
 *  Get Braintree token to possibly get existing payment methods for customers
 *
 * @param {string} braintreeTransactionId
 * @returns {Promise<{token: string, status: string, message: string}>} Whether or not resend succeeded and error message from api if fail
 */
export const getBraintreeToken = async (braintreeTransactionId) => {
  const defaultReturn = (messagePrefix) => ({
    token: BRAINTREE_DEFAULT_TOKEN,
    success: true,
    message: `${messagePrefix}; returning default`,
  }); // this will have no existing payment methods

  if (!braintreeTransactionId) {
    return defaultReturn('No transation id applied');
  }

  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/transactions/braintree/token/${braintreeTransactionId}`);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';
    const token = path(['data', 'token'], data) || '';

    if (success && token) {
      return {
        success,
        token,
        message: 'Successful call',
      };
    }
    return defaultReturn('Not a successful call');
  } catch (e) {
    return defaultReturn('Found error');
  }
};

/**
 *  Charge payment
 *
 * @param {{amount: number, nonce: string, orderId: string}}
 * @returns {Promise<{success: boolean, message: string, data: object, error: string}>}
 * Whether or not resend succeeded and error message from api if fail
 */
export const chargeBraintreePayment = async (amount, nonce, orderId) => {
  if (!orderId && !nonce) {
    return { success: false, message: 'No input values' };
  }

  const requestData = { amount, nonce, orderId };

  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/transactions/braintree/payment`, requestData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';

    return {
      success,
      data: data.data,
    };
  } catch (e) {
    return { success: false, error: e };
  }
};

/**
 * *  Get labels for batch
 *
 * @param {batchId: string}
 * @returns {Promise<{success: boolean, message: string, data: object, error: string}>}
 * Whether or not resend succeeded and error message from api if fail
 * @throws {{success: boolean, error: string}}
 */
export const getZplLabelsForBatch = async (batchId) => {
  if (!batchId) {
    return { success: false, message: 'No input values' };
  }

  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}/zpl-labels`);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';

    return {
      success,
      data: data.data,
    };
  } catch (e) {
    return { success: false, error: e };
  }
};

/**
 * Get Docbox Label for batch
 */
export const getDocboxLabelForBatch = async (batchId) => {
  if (!batchId) {
    return { success: false, message: 'No input values' };
  }

  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/batches/${batchId}/docbox-label`);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';

    return {
      success,
      data: data.data,
    };
  } catch (e) {
    return { success: false, error: e };
  }
};

/**
 *  Get ZPL label for suborder
 *
 * @param {suborderId: string}
 * @returns {Promise<{success: boolean, message: string, data: object, error: string}>}
 * Whether or not resend succeeded and error message from api if fail
 * @throws {{success: boolean, error: string}}
 */
export const getZplLabelForSuborder = async (suborderId) => {
  if (!suborderId) {
    return { success: false, message: 'No input values' };
  }

  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/zpl-label`);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';

    return {
      success,
      data: data.data,
    };
  } catch (e) {
    return { success: false, error: e };
  }
};

const getFromOms = async (extension) => {
  try {
    const { data } = await axios.get(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/${extension}`);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';

    return {
      success,
      data: data.data,
    };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const getProductFeedArray = async () => {
  return getFromOms('products');
};

export const getAllDeliveryAreas = async () => {
  return getFromOms('deliveryareas');
};

export const getCustomerTrackingLink = async (suborderId) => getFromOms(`suborders/${suborderId}/tracking-link`);

export const issueCreditsToCustomer = async (email, amount, reason, tag) => {
  if (!email && !amount && !reason && !tag) {
    return { success: false, message: 'No input values' };
  }

  const requestData = { email, amount, reason, tag };
  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/customers/credits`, requestData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';

    return {
      success,
      data: data.data,
    };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const updateCreditById = async (id, creditAmount, remainingAmount, reason, tag) => {
  if (!id && !creditAmount && !remainingAmount && !reason && !tag) {
    return { success: false, message: 'No input values' };
  }

  const requestData = { creditAmount, remainingAmount, reason, tag };

  try {
    const { data } = await axios.patch(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/customers/credits/${id}`, requestData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';

    return {
      success,
      data: data.data,
    };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const deleteCreditById = async (creditId, reason, tag) => {
  if (!creditId && !reason && !tag) {
    return { success: false, message: 'No input values' };
  }

  const requestData = { creditId, reason, tag };

  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/customers/credits/delete`, requestData);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';

    return {
      success,
      data: data.data,
    };
  } catch (e) {
    return { success: false, error: e };
  }
};

export const remedySuborder = async (suborderId, args) => {
  if (!args) {
    return { success: false, message: 'No input values' };
  }

  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/suborders/${suborderId}/remedy`, args);
    const success = (path(['status'], data) || '').toUpperCase() === 'SUCCESS';

    return {
      success,
      data: data.data,
    };
  } catch (e) {
    return { success: false, message: e, error: e };
  }
};

export const saveDeliveryWindow = async (payload) => {
  try {
    const { data } = await axios.put(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/delivery-windows/${payload.id}`, payload);
    return {
      success: data.status,
      window: data.data,
    };
  } catch (e) {
    console.error('Error saving delivery window:', e);

    if (e.response && e.response.data) {
      console.error('Server Error:', e.response.data);
      return {
        success: false,
        error: e.response.data,
      };
    }
    if (e.request) {
      console.error('Request Error:', e.request);
      return {
        success: false,
        error: 'No response received from server.',
      };
    }
    console.error('General Error:', e.message);
    return {
      success: false,
      error: e.message,
    };
  }
};

export const saveDeliveryWindowStatus = async ({ id, isActive }) => {
  try {
    const { data } = await axios.put(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/delivery-windows/${id}/status`, { isActive });
    return {
      status: data.status,
    };
  } catch (e) {
    console.error('Error saving delivery window status:', e);

    if (e.response && e.response.data) {
      console.error('Server Error:', e.response.data);
      return {
        success: false,
        error: e.response.data,
      };
    }
    if (e.request) {
      console.error('Request Error:', e.request);
      return {
        success: false,
        error: 'No response received from server.',
      };
    }
    console.error('General Error:', e.message);
    return {
      success: false,
      error: e.message,
    };
  }
};

export const createDeliveryWindow = async (payload) => {
  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/delivery-windows`, payload);
    return {
      success: data.status,
      window: data.data,
    };
  } catch (e) {
    console.error('Error creating delivery window:', e);

    if (e.response && e.response.data) {
      console.error('Server Error:', e.response.data);
      return {
        success: false,
        error: e.response.data,
      };
    }
    if (e.request) {
      console.error('Request Error:', e.request);
      return {
        success: false,
        error: 'No response received from server.',
      };
    }
    console.error('General Error:', e.message);
    return {
      success: false,
      error: e.message,
    };
  }
};

export const createDeliveryArea = async (deliveryArea, schedules) => {
  try {
    const { data } = await axios.post(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/delivery-areas`, { deliveryArea, schedules });
    return {
      success: data.status,
      areas: data,
    };
  } catch (e) {
    console.error(e);
    return {
      success: false,
    };
  }
};

export const updateDeliveryArea = async (deliveryArea, schedules) => {
  try {
    const { data } = await axios.put(`${ORDER_MANAGEMENT_SERVICE_BASE_URL}/delivery-areas/${deliveryArea.id}`, { deliveryArea, schedules });
    return {
      success: data.status,
      areas: data,
    };
  } catch (e) {
    console.error(e);
    return {
      success: false,
    };
  }
};
