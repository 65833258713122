import React, { useState, useEffect } from 'react';
import { Pane, Heading, Button, Tablist, Tab, LinkIcon, IconButton, ShareIcon } from 'evergreen-ui';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import Details from './content/Details';
import ChangeLogs from './content/ChangeLogs';
import Product from './content/Product';
import CreateOrEditAdjustmentModal from '../CreateOrEditAdjustmentModal';
import getUrlOfInventoryRecordPage from './common/getUrlOfInventoryRecordPage';
import openInventoryRecordInNewTab from './common/openInventoryRecordInNewTab';
import openNetSuiteLot from './common/openNetSuiteLot';
import SummaryOfAdjustments from './content/SummaryOfAdjustments';
import './InventoryRecordContent.css';
import { NETSUITE_INVENTORY_FEATURE_FLAG } from '../../../../config';

const tabNames = NETSUITE_INVENTORY_FEATURE_FLAG ?
  {
    lotDetails: 'Lot Details',
    product: 'Product',
  }
  : {
    lotDetails: 'Lot Details',
    summaryOfAdjustments: 'Adjustments',
    changeLogs: 'Change Log',
    product: 'Product',
  };

const tabsAccordingToHashTag = (setSelectedIndex) => {
  const { hash } = window.location;
  const activeTab = hash.substring(1);
  switch (activeTab) {
    case 'Lot-Details':
      setSelectedIndex(0);
      break;
    case 'Adjustments':
      setSelectedIndex(1);
      break;
    case 'Change-Log':
      setSelectedIndex(2);
      break;
    case 'Product':
      setSelectedIndex(3);
      break;
    default:
      setSelectedIndex(0);
      break;
  }
};

const InventoryRecordContent = ({
  selectedLot,
  makeInventoryRecordModalVisible = () => { },
  fc = {},
  completeCreatingOrEditingAdjustment = () => { },
  showCopyLinkIcon = true,
}) => {
  const [showNewAdjustmentModal, setShowNewAdjustmentModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const possibleTabs = NETSUITE_INVENTORY_FEATURE_FLAG ?
    [
      {
        tab_name: tabNames.lotDetails,
        component: Details,
      },
      { tab_name: tabNames.product, component: Product },
    ]
    :
    [
      {
        tab_name: tabNames.lotDetails,
        component: Details,
      },
      { tab_name: tabNames.summaryOfAdjustments, component: SummaryOfAdjustments },
      { tab_name: tabNames.changeLogs, component: ChangeLogs },
      { tab_name: tabNames.product, component: Product },
    ];

  const [tabs] = React.useState(possibleTabs);

  useEffect(() => {
    tabsAccordingToHashTag(setSelectedIndex);
  }, []);

  const copyUrlOfInventoryRecordPageToClipBoard = () => navigator.clipboard.writeText(getUrlOfInventoryRecordPage(selectedLot?.internalLotId));
  return (
    <div>
      <Pane marginTop={15} display="flex" padding={16} background="tint2" borderRadius={3}>
        <Pane flex={1} alignItems="center">
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <Heading size={600}>{selectedLot?.productName}</Heading>
            {showCopyLinkIcon ? (
              <Pane maxHeight={4}>
                <Tooltip position="right" title="Copy Link" arrow="true" size="small">
                  <div className="pl-2">
                    <IconButton
                      className="pb-0"
                      size="small"
                      appearance="minimal"
                      icon={LinkIcon}
                      onClick={copyUrlOfInventoryRecordPageToClipBoard}
                    />
                  </div>
                </Tooltip>
              </Pane>
            ) : (
              <div />
            )}
            <Tooltip position="right" title="Open in new tab" arrow="true" size="small">
              <IconButton
                className="pb-0"
                size="small"
                color="info"
                appearance="minimal"
                icon={ShareIcon}
                onClick={() => {
                  openInventoryRecordInNewTab(selectedLot?.internalLotId);
                }}
              />
            </Tooltip>
          </div>
          {selectedLot?.externalLotId && <div className="mt-3">Lot {selectedLot?.externalLotId}</div>}
        </Pane>
        <Pane>
          <Button
            margin={10}
            appearance="primary"
            onClick={() => openNetSuiteLot(selectedLot?.netsuiteInventoryNumberId)}
            style={{ cursor: 'pointer', backgroundColor: '#4B93EE' }}
          >
            View NetSuite Lot Record
          </Button>
        </Pane>
      </Pane>
      <Pane marginTop={15}>
        <Pane>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Tablist marginBottom={16}>
              {tabs.map((tab, index) => (
                <Tab
                  aria-controls={`panel-${tab.tab_name}-${index}`}
                  isSelected={index === selectedIndex}
                  key={tab.tab_name}
                  onSelect={() => {
                    setSelectedIndex(index);
                    window.location.hash = `#${tab.tab_name.replace(/\s+/g, '-')}`;
                  }}
                  className="tabfocus"
                >
                  {tab.tab_name}
                </Tab>
              ))}
            </Tablist>
          </div>

          <Pane padding={16} background="tint1" flex="1">
            {tabs.map((tab, index) => (
              <Pane
                aria-labelledby={tab.tab_name}
                aria-hidden={index !== selectedIndex}
                display={index === selectedIndex ? 'block' : 'none'}
                key={tab.tab_name}
                role="tabpanel"
              >
                {tab.tab_name === tabNames.lotDetails && <tab.component details={selectedLot.details} status={selectedLot?.status} />}
                {NETSUITE_INVENTORY_FEATURE_FLAG && tab.tab_name === tabNames.summaryOfAdjustments && (
                  <tab.component
                    adjustments={selectedLot.adjustments}
                    adjustmentsSummary={selectedLot.adjustmentsSummary}
                    internalLotId={selectedLot?.internalLotId}
                    successFunction={completeCreatingOrEditingAdjustment}
                  />
                )}
                {tab.tab_name === tabNames.changeLogs && <tab.component changelogs={selectedLot.changelogs} />}
                {tab.tab_name === tabNames.product && <tab.component productSku={selectedLot.productSku} />}
              </Pane>
            ))}
          </Pane>
        </Pane>
        {showNewAdjustmentModal && (
          <CreateOrEditAdjustmentModal
            showModal={showNewAdjustmentModal}
            hideModalFunction={() => {
              makeInventoryRecordModalVisible();
              setShowNewAdjustmentModal(false);
            }}
            internalLotId={selectedLot?.internalLotId}
            externalLotId={selectedLot?.externalLotId}
            productName={selectedLot?.productName}
            changelogs={selectedLot?.changelogs}
            fc={fc}
            backAction={() => {
              makeInventoryRecordModalVisible();
              setShowNewAdjustmentModal(false);
            }}
            successFunction={completeCreatingOrEditingAdjustment}
            successFunctionMessage="Successfully created new adjustment"
          />
        )}
      </Pane>
    </div>
  );
};

InventoryRecordContent.propTypes = {
  selectedLot: PropTypes.object,
  fc: PropTypes.object,
  completeCreatingOrEditingAdjustment: PropTypes.func,
  makeInventoryRecordModalVisible: PropTypes.func,
  showCopyLinkIcon: PropTypes.bool,
};

InventoryRecordContent.defaultProps = {
  selectedLot: {},
  fc: {},
  completeCreatingOrEditingAdjustment: () => { },
  makeInventoryRecordModalVisible: () => { },
  showCopyLinkIcon: true,
};

export default InventoryRecordContent;
